import React, { useState } from 'react';

import { VisibilityOff, Visibility } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Typography,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { updateUser, changeUserPassword } from '../../../services/user.service';
import { IUser } from '../../../types/user';

export type EditPasswordFormType = {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
};

type EditPasswordType = {
  user: IUser;
};

const EditPassword: React.FC<EditPasswordType> = ({ user }) => {
  const { register, handleSubmit, reset } = useForm<EditPasswordFormType>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleEditMode = () => {
    reset();
    setError(false);
    setShowPassword(false);
    setEditMode(!editMode);
  };

  const onSubmit = async (data: EditPasswordFormType) => {
    setIsLoading(true);
    if (data.password !== data.passwordConfirm) {
      setIsLoading(false);
      setError(true);
      return;
    }
    // const updatedUser = await updateUser(user.id, { password: data.password });
    // if (updatedUser && updatedUser.id) {
    //   setTimeout(() => {
    //     setIsLoading(false);
    //     setError(false);
    //     setEditMode(!editMode);
    //   }, 2000);
    // } else {
    //   setIsLoading(false);
    //   setError(true);
    // }

    try {
      const successMessage = await changeUserPassword(
        user.cognitoUser,
        data.oldPassword,
        data.password,
      );
      setIsLoading(false);
      setError(false);
      setEditMode(!editMode); // Toggle or set edit mode accordingly
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      setError(true); // Show error feedback
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      {editMode && (
        <>
          <Typography sx={{ mt: 2 }} variant="h6" gutterBottom>
            Change Password
          </Typography>
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="oldPassword">Old password</InputLabel>
            <OutlinedInput
              id="oldPassword"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              inputProps={{ readOnly: !editMode }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              label="old password"
              {...register('oldPassword', { required: true })}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              inputProps={{ readOnly: !editMode }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              label="Password"
              {...register('password', { required: true })}
            />
          </FormControl>
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="passwordConfirm">Confirm password</InputLabel>
            <OutlinedInput
              id="passwordConfirm"
              fullWidth
              required
              type={showPassword ? 'text' : 'password'}
              inputProps={{ readOnly: !editMode }}
              endAdornment={(
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              label="Confirm password"
              {...register('passwordConfirm', { required: true })}
            />
          </FormControl>
        </>
      )}

      {error && (
        <Box sx={{ mx: 1, my: 2, color: 'error.main' }}>
          The passwords do not match!
        </Box>
      )}

      <Box sx={{ pt: 2, justifyContent: 'end', display: 'flex' }}>
        {editMode ? (
          <>
            <Button onClick={toggleEditMode} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <Button onClick={toggleEditMode}>Change Password</Button>
        )}
      </Box>
    </Box>
  );
};

export default EditPassword;
