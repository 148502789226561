import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, BoxProps, styled } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Navbar from '../components/navbar/navbar';
import Sidebar from '../components/sidebar/sidebar';
import AuthContext from '../context/auth-context';
import SearchBarContext from '../context/search-bar.context';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled(Box)<BoxProps>({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundColor: 'background.default',
});

const MainStyle = styled(Box)<BoxProps>(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  paddingTop: APP_BAR_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

type LayoutProps = {
  // Empty Props
}

const Layout: FC<LayoutProps> = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { setSearchTerm } = useContext(SearchBarContext);

  useEffect(() => {
    if (location.pathname !== '/search') {
      setSearchTerm('');
    }
  }, [setSearchTerm, location]);

  const openSidebar = () => {
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  return (
    <RootStyle>

      {user
          && (
          <>
            <Navbar openSidebar={openSidebar} user={user} />
            <Sidebar sidebarOpen={open} closeSidebar={closeSidebar} user={user} />
          </>
          )}

      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default Layout;
