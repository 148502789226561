import React, { useContext, useEffect, useState } from 'react';

import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import BoxContext from '../../context/box-context';
import SearchBarContext from '../../context/search-bar.context';
import { getEntitiesForSearch } from '../../services/get.service';
import { IBox } from '../../types/box';
import { INode } from '../../types/node';
import NodeList from '../utils/node-list/node-list';

export const SearchResults: React.FC = () => {
  const { searchTerm } = useContext(SearchBarContext);
  const { boxes } = useContext(BoxContext);
  const [filteredBoxes, setFilteredBoxes] = useState<IBox[]>([]);
  const [nodes, setNodes] = useState<INode[]>([]);

  useEffect(() => {
    const getEntities = async () => {
      const results = await getEntitiesForSearch(searchTerm);
      if (results && results.length > 0) {
        setNodes(results || []);
      }
    };

    getEntities();
  }, [searchTerm, boxes, setFilteredBoxes]);

  const foundResults = filteredBoxes.length > 0
    || nodes.length > 0;

  const showOthers = nodes.length > 0;

  const isSearchTerm = searchTerm !== '';
  const areThereResults = isSearchTerm && foundResults;

  const noResults = (
    <Typography variant="body1" sx={{ p: '1em', textAlign: 'center' }}>
      Sorry, there are no results for this search 😕
    </Typography>
  );
  const emptySearchTerm = (
    <Typography variant="body1" sx={{ p: '1em', textAlign: 'center' }}>
      Enter something to start the search!
    </Typography>
  );
  const nodeList = (showOthers && (
    <Box>
      <NodeList nodes={nodes} wrapper fancyBox />
    </Box>
  ));
  const emptyResultScreen = isSearchTerm ? noResults : emptySearchTerm;

  return (
    <Container>
      {areThereResults
        ? (
          <Stack>
            {nodeList}
          </Stack>
        )
        : emptyResultScreen}
    </Container>
  );
};
