import { AxiosRequestConfig } from 'axios';

import { awsGetToken } from './aws.service';

export const getConfig = async (): Promise<AxiosRequestConfig> => {
  try {
    const accessToken = await awsGetToken();
    if (!accessToken) {
      throw new Error('User not authenticated.');
    }
    return { headers: { Authorization: `Bearer ${accessToken}` } };
  } catch (error) {
    throw new Error(`Token expired. Please log in. - ${error}`);
  }
};
