import React from 'react';

// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

type SearchNotFoundProps = {
  searchQuery: string
};

const SearchNotFound: React.FC<SearchNotFoundProps> = ({ searchQuery = '', ...other }) => (
  <Paper {...other}>
    <Typography gutterBottom align="center" variant="subtitle1">
      Nothing found 😔
    </Typography>
    <Typography variant="body2" align="center">
      No results found for &nbsp;
      <strong>
        &quot;
        {searchQuery}
        &quot;
      </strong>
      . Check if you have mistyped or use a different search term.
    </Typography>
  </Paper>
);

export default SearchNotFound;
