import React, { FC } from 'react';

import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner: FC = () => (
  <Box sx={{ display: 'grid', height: '100%', placeItems: 'center' }}>
    <CircularProgress />
  </Box>
);

export default LoadingSpinner;
