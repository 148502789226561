import axios from 'axios';

import { getConfig } from './utils';
import { INode } from '../types/node';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const updateNode = async (node: INode): Promise<INode | undefined> => {
  try {
    const config = await getConfig();
    const createdNode = (await axios.patch(`${baseUrl}/update`, node, config)).data as INode;
    return createdNode;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};
