import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, styled, Modal, ModalProps, Typography } from '@mui/material';

import { deleteUser } from '../../../../services/user.service';
import { IUser } from '../../../../types/user';

const style = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type DeleteUserType = {
  user: IUser;
  children: React.ReactNode;
}

const DeleteUser: React.FC<DeleteUserType> = ({ user, children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const success = await deleteUser(user.id);
    if (success) {
      setTimeout(() => {
        setIsLoading(false);
        handleClose();
        window.location.reload();
      }, 500);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box onClick={handleOpen}>
        { children }
      </Box>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h4" component="h2">
            Delete user
            {' '}
            { user.displayName }
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Do you want to delete the account with the name
            {' '}
            { user.displayName }
            ?
          </Typography>

          <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              onClick={onSubmit}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </ModalStyle>
    </Box>
  );
};

export default DeleteUser;
