import React, { useContext } from 'react';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Stack,
} from '@mui/material';

import DeleteUser from './components/delete-account';
import EditAvatar from './components/edit-avatar';
import EditPassword from './components/edit-password';
import EditProfile from './components/edit-profile';
import AuthContext from '../../context/auth-context';

// type ProfileProps = {
//   user: IUser
// }

const Profile: React.FC = () => {
  const { user } = useContext(AuthContext);
  const getInitials = () => {
    const f = user.firstname ? user.firstname[0] : '';
    const l = user.lastname ? user.lastname[0] : '';
    return `${f}${l}`;
  };
  return user
    ? (
      <Container>
        <Card sx={{ py: 4 }}>
          <CardContent>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6}>
              <Stack spacing={1} sx={{ alignItems: 'center' }}>
                <Avatar
                  src={user.imageUrl}
                  sx={{ width: 112, height: 112 }}
                >
                  {getInitials()}
                </Avatar>
                <EditAvatar user={user} />
              </Stack>
              <Stack spacing={2}>
                <EditProfile user={user} />
                { user.auth !== 'microsoft'
                && <EditPassword user={user} />}
              </Stack>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'end' }} />
        </Card>
        <Box sx={{ justifyContent: 'center', display: 'flex', pt: 6 }}>
          <DeleteUser
            user={user}
          >
            <Button color="error">
              Delete Account
            </Button>
          </DeleteUser>
        </Box>
      </Container>
    )
    : (
      <Container />
    );
};

export default Profile;
