import axios from 'axios';

import { awsGetUser, awsLogin, awsLogout, awsSignUp } from './aws.service';
import { getConfig } from './utils';
import userPool from '../config/cognitoConfig';
import { IUser } from '../types/user';

const baseUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : '';

export const getUser = async (): Promise<IUser | undefined> => {
  try {
    const awsUser = await awsGetUser();
    if (!awsUser) return undefined;
    return await axios.get(`${baseUrl}/users/`, await getConfig()).then(
      (response) => response.data as IUser,
    );
  } catch (error) {
    return undefined;
  }
};

export const login = async (
  email: string,
  password: string,
): Promise<IUser | undefined> => {
  try {
    const awsUser = await awsLogin(email, password);
    if (!awsUser) return undefined;
    return getUser();
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const invite = async (newUser: Partial<IUser>): Promise<IUser | undefined> => {
  if (!newUser.email) {
    console.error('Email not provided.');
    return undefined;
  }
  try {
    const user = await axios.post(
      `${baseUrl}/users/invite`,
      newUser,
      await getConfig(),
    );
    return user.data as IUser;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

export const followInvite = async (newUser: Partial<IUser>): Promise<IUser | undefined> => {
  if (!newUser.email || !newUser.password) {
    console.error('Email or password not provided.');
    return undefined;
  }
  try {
    const res = await axios.post(
      `${baseUrl}/users/validate-otp`,
      { email: newUser.email, otp: newUser.otp },
    );

    if (!res.data) throw new Error('Invalid OTP or email');
  } catch (error) {
    throw new Error('Error by validating OTP or email');
  }

  return new Promise((resolve, reject) => {
    userPool.signUp(
      newUser.email,
      newUser.password,
      [],
      null,
      async (err, res) => {
        if (err) {
          return reject(err); // Reject the promise on error
        }
        try {
          const { password, ...withoutPassword } = newUser;
          const backendUser = {
            ...withoutPassword,
            id: res.userSub, // The unique identifier for the user
          };
          await axios.post(`${baseUrl}/users/follow-invite`, backendUser);

          const user = await login(newUser.email, newUser.password);
          return resolve(user);
        } catch (postError) {
          return reject(err);
        }
      },
    );
  });
};

export const signup = async (newUser: Partial<IUser>, workspace: string): Promise<IUser | undefined> => {
  if (!newUser.email || !newUser.password) {
    throw Error('Email or password not provided.');
  }

  const awsUser = await awsSignUp(newUser.email, newUser.password);
  if (!awsUser) return undefined;
  const { password, ...withoutPassword } = newUser;
  const User = {
    ...withoutPassword,
    id: awsUser.id, // The unique identifier for the user
  };
  await axios.post(`${baseUrl}/users/workspace`, {
    user: User,
    dbName: workspace,
  });
  return getUser();
};

export const logout = async (): Promise<boolean> => {
  try {
    await awsLogout();
    return true;
  } catch (error) {
    // Error by logout
    return false;
  }
};
