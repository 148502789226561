import React, { FC, useContext, useState, useEffect } from 'react';

// Material UI
import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// packages
import { FieldValues, useForm } from 'react-hook-form';
// custom
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// import Icon from '../../assets/logo/icon.svg';
import AuthContext from '../../context/auth-context';
import { followInvite, signup } from '../../services/auth.service';

// eslint-disable-next-line max-len
const CONTACT_MAIL = 'mailto:info@knowledge-in-a-box.de?cc=till.hoffmann@knowledge-in-a-box.de&subject=Error%20by%20creating%20new%20account&body=Dear%20Knowledge%20in%20a%20Box%20Team%2C%0D%0A%0D%0AI%20had%20difficulties%20registering.%0D%0A%0D%0AName%3A%0D%0AEmail%3A%0D%0ACompany%3A%0D%0A%0D%0AKind%20regards%2C%0D%0A';

const ACCESS_REQUEST = '/';

type SignupFormType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordRepeat: string;
  company: string;
  legal: boolean;
};

const Signup: FC = () => {
  const [urlParams, setUrlParams] = useState({ email: undefined, otp: undefined });
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, setError, setValue } = useForm<SignupFormType>();
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('email')) {
      setValue('email', params.get('email'), { shouldValidate: true });

      setUrlParams({ email: params.get('email'), otp: params.get('otp') });
    }
  }, [setValue]);

  const onSubmit = async (data: FieldValues) => {
    const isFollowInvite = data.email && urlParams.otp;

    setIsLoading(true);
    // Check if passwords match
    if (data.password !== data.passwordRepeat) {
      setError('passwordRepeat', { type: 'focus' });
      setIsLoading(false);
      return;
    }

    // Check if legal is checked
    if (!data.legal) {
      setError('legal', { type: 'focus' });
      setIsLoading(false);
      return;
    }

    const newUser = {
      firstname: data.firstName,
      lastname: data.lastName,
      email: data.email,
      password: data.password,
      otp: urlParams.otp ? urlParams.otp : undefined,
      score: 0,
      legal: data.legal,
    };

    let user;
    try {
      if (isFollowInvite) {
        // User got invited
        user = await followInvite(newUser);
      } else {
      // User is creating new workspace
        const cleanCompany = data.company.replace(/[^a-zA-Z0-9]/g, '');
        user = await signup(newUser, cleanCompany);
      }
    } catch (err: any) {
      setDisplayError(err ? err.toString() : 'An error occurred');
    } finally {
      setIsLoading(false);
    }

    if (user) {
      setUser(user);
      navigate('/');
    }
    setIsLoading(false);
  };

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Card sx={{ px: 2, py: 2, mb: 4 }}>
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h5" variant="h5">
            Register
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              label="First Name"
              type="name"
              autoComplete="firstName"
              autoFocus
              required
              error={!!errors.firstName}
              helperText={errors.firstName && 'Please enter a first name'}
              {...register('firstName', { required: true })}
            />
            <TextField
              margin="normal"
              fullWidth
              label="Last Name"
              type="name"
              autoComplete="lastName"
              required
              error={!!errors.lastName}
              helperText={errors.firstName && 'Please enter a last name'}
              {...register('lastName', { required: true })}
            />

            <TextField
              margin="normal"
              fullWidth
              label="Email Address"
              type="email"
              autoComplete="email"
              required
              disabled={urlParams?.email !== undefined}
              error={!!errors.email}
              helperText={errors.email && 'Please enter a valid email address'}
              {...register('email', {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="new-password"
              error={!!errors.password}
              helperText={errors.password && 'Please enter a password'}
              {...register('password', { required: true })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Repeat Password"
              type="password"
              autoComplete="new-password"
              error={!!errors.passwordRepeat}
              helperText={errors.passwordRepeat && 'The passwords must match'}
              {...register('passwordRepeat', { required: true })}
            />
            {!urlParams?.otp && (
            <TextField
              margin="normal"
              fullWidth
              label="Company"
              type="name"
              autoComplete="company"
              required
              error={!!errors.company}
              helperText={errors.company
                && 'The company name should be at least three characters long and not longer than 62 characters'}
              {...register('company', { required: true, minLength: 3, maxLength: 62 })}
            />
            )}
            <FormControl
              required
              component="fieldset"
              margin="normal"
              variant="standard"
              sx={{ px: 1 }}
            >
              <FormGroup>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color={errors.legal ? 'error' : 'primary'}
                      {...register('legal', { required: true })}
                    />
                                    )}
                  sx={{ color: errors.legal ? 'error.main' : 'inherit' }}
                  label={(
                    <Box>
                      I agree to the
                      {' '}
                      <Link
                        component={RouterLink}
                        to="https://knowledge-in-a-box.de/privacy-policy"
                        variant="body1"
                        target="_blank"
                      >
                        Terms
                      </Link>
                      {' '}
                      and
                      {' '}
                      <Link
                        component={RouterLink}
                        to="https://knowledge-in-a-box.de/privacy-policy"
                        variant="body1"
                        target="_blank"
                      >
                        Privacy Policy
                      </Link>
                    </Box>
                                    )}
                />
              </FormGroup>
            </FormControl>

            {displayError && (
            <Typography
              variant="body1"
              color="error"
              sx={{ mt: 1, mb: 1 }}
            >
              {displayError}
            </Typography>
            )}

            <LoadingButton
              sx={{ mt: 3, mb: 2, width: '100%' }}
              type="submit"
              variant="contained"
              loading={isLoading}
              startIcon={<LoginIcon />}
              loadingPosition="start"
            >
              Register
            </LoadingButton>
            <Button
              sx={{ mt: 2, mb: 2, width: '100%' }}
              variant="text"
              component={RouterLink}
              to={ACCESS_REQUEST}
            >
              Login
            </Button>
            <Box sx={{ textAlign: 'center' }}>
              <Link component={RouterLink} to={CONTACT_MAIL} variant="body2">
                Contact
              </Link>
            </Box>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default Signup;
