import React from 'react';

import { PlayerEvent, Player } from '@lottiefiles/react-lottie-player';

import SuccessBoxCretion from '../../assets/animations/success-box-creation.json';

type BoxCreationSuccessProps = {
  handleClose: () => void
}

const BoxCreationSuccess: React.FC<BoxCreationSuccessProps> = ({ handleClose }) => {
  const onAnimationEvent = (event: PlayerEvent) => {
    if (event === 'complete') {
      handleClose();
    }
  };

  return (
    <Player
      autoplay
      speed={1.25}
      onEvent={onAnimationEvent}
      src={SuccessBoxCretion}
      controls={false}
      style={{ width: '100%' }}
    />
  );
};

export default BoxCreationSuccess;
