import React, { useContext, useEffect, useRef, useState } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';// material
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box, Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

import DeleteNode from './delete-node';
import AuthContext from '../../../context/auth-context';
import FavContext from '../../../context/fav-context';
import { addOrgFavorite } from '../../../services/connect.service';
import { deleteOrganisationFavorite } from '../../../services/delete.service';
import { updateNode } from '../../../services/update.service';
import { INode } from '../../../types/node';

// ----------------------------------------------------------------------

const DONT_DELETE = ['person'];

type NodeOptionsProps = {
  node: INode;
  isAdmin: boolean;
  toggleEditMode: () => void;
}

const NodeOptions: React.FC<NodeOptionsProps> = ({ node, isAdmin, toggleEditMode }) => {
  const ref = useRef(null);
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { isOrgFav, toggleOrgFav } = useContext(FavContext);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    setIsVerified(node.verified);
  }, [node.verified, setIsVerified]);

  const toggleOrgFavorite = async () => {
    if (!node || !user || !user?.nodeId) {
      return;
    }
    if (isOrgFav(node.id)) {
      // remove from Favorites
      const result = await deleteOrganisationFavorite(node.id);
      if (result) {
        toggleOrgFav(node.id);
      }
    } else {
      // add to Favorites
      const favNode = await addOrgFavorite(node.id);
      if (favNode) {
        toggleOrgFav(node.id);
      }
    }
  };

  const toggleIsVerified = async () => {
    if (!node || !user || !user?.nodeId) {
      return;
    }
    if (isVerified) {
      // remove from Verified
      const result = await updateNode({ ...node, verified: false });
      if (result) {
        setIsVerified(false);
      }
    } else {
      // add to Verified
      const result = await updateNode({ ...node, verified: true });
      if (result) {
        setIsVerified(true);
      }
    }
  };

  const orgFavoriteIcon = (
    <ListItemIcon>
      {isOrgFav(node.id)
        ? <BookmarkIcon color="primary" />
        : <BookmarkAddIcon />}
    </ListItemIcon>
  );
  const orgFavoriteTitle = isOrgFav(node.id)
    ? 'Remove from organisation favorites'
    : 'Add to organisation favorites';

  const verifiedLabel = isVerified
    ? 'Mark as not verified'
    : 'Mark as verified';

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} sx={{ m: 1 }}>
        <MoreVertIcon />
      </IconButton>

      {isAdmin && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{ sx: { maxWidth: '100%' } }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >

          <MenuItem sx={{ color: 'text.secondary' }} onClick={toggleOrgFavorite}>
            {orgFavoriteIcon}
            <ListItemText primary={orgFavoriteTitle} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          <MenuItem sx={{ color: 'text.secondary' }} onClick={toggleIsVerified}>
            <ListItemIcon>
              <VerifiedIcon color={isVerified ? 'primary' : 'inherit'} />
            </ListItemIcon>
            <ListItemText primary={verifiedLabel} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>

          {!DONT_DELETE.includes(node.label)
            && (
            <Box>
              <MenuItem sx={{ color: 'text.secondary' }} onClick={toggleEditMode}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
              </MenuItem>
              <DeleteNode node={node}>
                <MenuItem sx={{ color: 'text.secondary' }}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
                </MenuItem>
              </DeleteNode>
            </Box>
            )}
        </Menu>
      )}
    </>
  );
};

export default NodeOptions;
