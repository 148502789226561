import React, { useEffect, useState } from 'react';

import {
  Grid,
  List,
} from '@mui/material';

import { getNodeAndLabelArray, removeBoxNodes, removeNodeFromLists } from './helper';
import NodeSubList from './node-sub-list';
import { IBox } from '../../../types/box';
import { INode } from '../../../types/node';
import BoxList from '../box-list/box-list';

type NodeListProps = {
  nodes: INode[];
  wrapper: boolean;
  fancyBox: boolean;
  // eslint-disable-next-line react/require-default-props
  editMode?: boolean;
  // eslint-disable-next-line react/require-default-props
  parentNode?: INode | undefined;
  // eslint-disable-next-line react/require-default-props
  breakpoints?: boolean;
}

const NodeList: React.FC<NodeListProps> = (
  { nodes, fancyBox, editMode = false, wrapper = true, breakpoints = true, parentNode = undefined },
) => {
  const [nodesList, setNodesList] = useState<INode[][]>([[]]);
  const [labelList, setLabelList] = useState<string[]>([]);
  const [boxes, setBoxes] = useState<IBox[]>([]);
  const [longLabelList, setLongLabelList] = useState(false);

  useEffect(() => {
    const { labelsArr, nodesArr } = getNodeAndLabelArray(nodes);
    setLongLabelList(labelsArr.length > 2);

    if (fancyBox) {
      const cleanedLists = removeBoxNodes(labelsArr, nodesArr);
      setNodesList(cleanedLists.nodesList);
      setLabelList(cleanedLists.labelList);
      setBoxes(cleanedLists.boxNodes);
    } else {
      setNodesList(nodesArr);
      setLabelList(labelsArr);
    }
  }, [setNodesList, setLabelList, setBoxes, nodes, fancyBox]);

  const onDeleteConnection = (node: INode) => {
    const { labelsArr, nodesArr } = removeNodeFromLists(node, labelList, nodesList);
    setNodesList(() => [...nodesArr]);
    setLabelList(() => [...labelsArr]);
    setLongLabelList(labelsArr.length > 2);
  };
  return (
    <List
      aria-labelledby="nested-list-subheader"
    >
      <Grid container spacing={2}>
        {
                    (fancyBox && boxes.length > 0)
          && (
          <Grid
            item
            xs={12}
            md={breakpoints && longLabelList ? 6 : 12}
            lg={breakpoints && longLabelList ? 6 : 12}
          >
            <BoxList boxes={boxes as IBox[]} />
          </Grid>
          )
                }
        {
                    labelList.map((label, i) => (
                      <Grid
                        item
                        key={label}
                        xs={12}
                        md={breakpoints && longLabelList ? 6 : 12}
                        lg={breakpoints && longLabelList ? 6 : 12}
                      >
                        <NodeSubList
                          label={label}
                          nodes={nodesList[i]}
                          onDeleteConnection={onDeleteConnection}
                          parentNode={parentNode}
                          wrapper={wrapper}
                          editMode={editMode}
                        />
                      </Grid>
                    ))
                }
      </Grid>
    </List>
  );
};

export default NodeList;
