import axios from 'axios';

import { getConfig } from './utils';
import { ConfluenceResDto } from '../types/confluence.dto';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const connectToConfluence = async (email: string, password: string, bUrl: string)
  : Promise<ConfluenceResDto> => {
  const config = await getConfig();

  const result = (
    await axios.post(
      `${baseUrl}/connectors/confluence`,
      { email, password, baseUrl: bUrl },
      config,
    )
  ).data as ConfluenceResDto;
  return result;
};
