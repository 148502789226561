import React, { ReactNode, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Typography,
  Button,
  Box,
} from '@mui/material';

import BoxCreationSuccess from './box-creation-success';
import { connectNodes } from '../../services/connect.service';
import { createNode, createWebsite } from '../../services/create.service';
import { INode } from '../../types/node';
import { IWebsite, IWebsiteBase } from '../../types/website';
import CustomModal, { CustomModalRef } from '../utils/modal/modal';
import { NodeSelection } from '../utils/node-selection/node-selection';

export type SuccessResult = {
  toNode: INode;
  fromNodes: INode[];
}

type ConnectNodesProps = {
  trigger: ReactNode;
  parentNode: INode | IWebsite | IWebsiteBase;
  onSuccess: (nodes: SuccessResult) => void;
}

const ConnectNodes: React.FC<ConnectNodesProps> = ({ trigger, parentNode, onSuccess }) => {
  const modalRef = React.useRef<CustomModalRef>(null);
  const [nodeToConnect, setNodeToConnect] = useState<INode | null>(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [animation, setAnimation] = useState(false);

  const createNewWebsite = async (tab: IWebsiteBase) => {
    const newWebsite = await createWebsite({
      name: tab.name,
      url: tab.url,
      favicon: tab.favicon,
      description: '',
    });
    return newWebsite;
  };

  const onSelect = (node: INode) => {
    setNodeToConnect(node);
  };

  const closeModal = () => {
    setNodeToConnect(null);
    setError('');
    setAnimation(false);
    modalRef.current?.handleClose();
  };

  const triggerAnimation = () => {
    setAnimation(true);
  };

  const onSubmit = async () => {
    setSubmitLoading(true);
    if (nodeToConnect !== null
      && nodeToConnect.name !== ''
      && nodeToConnect.label && (nodeToConnect.label !== 'website' || nodeToConnect.url !== '')
    ) {
      const getNodeToConnect = async (): Promise<INode | undefined> => {
        const newNode = nodeToConnect;
        // if the node to connect has a id of -1, it means that the user wants to create a new node
        if (nodeToConnect.id === -1) {
          // creating a new node
          try {
            const createdNode = await createNode(nodeToConnect);
            setSubmitLoading(false);
            return createdNode;
          } catch (e: any) {
            // check if error code is 403 and if so, show error message
            if (e.response.status === 403) {
              setError('Please upgrade your licence to create more nodes. Contact an admin for more information.');
            } else {
              setError(e.toString());
            }
            setSubmitLoading(false);
            return undefined;
          }
        }
        return newNode;
      };

      const getParentNode = async (): Promise<{node: IWebsite, isCreated: boolean } | undefined> => {
        if ('id' in parentNode) return { node: parentNode as IWebsite, isCreated: false };
        if (!('id' in parentNode)) {
          const website = await createNewWebsite(parentNode);
          if (!website) {
            setSubmitLoading(false);
            setError('Something has gone wrong. (Error: 1001)');
            return undefined;
          }
          return { node: website, isCreated: true };
        }
        return undefined;
      };
      const newNode = await getNodeToConnect();
      const root = await getParentNode();
      if (!newNode || !root) return;
      const { node } = root;
      const result = await connectNodes(node.id, [newNode.id]);
      if (result) {
        onSuccess({ toNode: node, fromNodes: [newNode] });
        setSubmitLoading(false);
        triggerAnimation();
      } else {
        setSubmitLoading(false);
        setError('Something went wrong. Please select at least one other object. (Error: 1002))');
      }
    } else {
      setSubmitLoading(false);
      setError('Please enter a name');
    }
  };

  return (
    <CustomModal
      ref={modalRef}
      triggerNode={trigger}
      padding={2}
    >
      <Typography id="modal-modal-title" variant="h2" component="h2" sx={{ pb: '1rem' }}>
        Connect information
      </Typography>

      {animation
        ? (
          <BoxCreationSuccess handleClose={closeModal} />
        )
        : (
          <Box>
            {parentNode
              && (
              <>
                <NodeSelection onSelect={onSelect} />

                {error
                    && (
                    <Typography color="error">
                      {error}
                    </Typography>
                    )}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button onClick={closeModal} color="inherit">
                    Cancel
                  </Button>

                  <LoadingButton
                    sx={{ ml: '1rem' }}
                    variant="contained"
                    loading={submitLoading}
                    startIcon={<SaveIcon />}
                    loadingPosition="start"
                    onClick={onSubmit}
                    disabled={nodeToConnect === null}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </>
              )}
          </Box>
        )}

    </CustomModal>
  );
};

export default ConnectNodes;
