import React from 'react';

import styled from '@emotion/styled';
import { Modal, ModalProps, Box } from '@mui/material';

const style = (width?: string, padding?: number) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: width || '800px',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  p: padding || 4,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type CustomModalProps = {
  children: React.ReactNode;
  triggerNode: React.ReactNode;
  onClose?: () => void;
  onOpen?: () => void;
  width?: string;
  padding?: number;
}

export type CustomModalRef = {
  handleClose: () => void;
  handleOpen: () => void;
}

type CustomModalType = CustomModalProps & React.RefAttributes<CustomModalRef>

const CustomModal: React.ForwardRefExoticComponent<CustomModalType> = React.forwardRef(
  ({ children, triggerNode, onClose, onOpen, width, padding }, ref) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
      setOpen(true);
      if (typeof onOpen !== 'undefined') {
        onOpen();
      }
    };
    const handleClose = () => {
      if (typeof onClose !== 'undefined') {
        onClose();
      }
      setOpen(false);
    };

    React.useImperativeHandle(ref, () => ({ handleClose, handleOpen }));

    return (
      <Box>
        <Box onClick={handleOpen}>
          { triggerNode }
        </Box>
        <ModalStyle
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={() => style(width, padding)}>
            { children }
          </Box>
        </ModalStyle>
      </Box>
    );
  },
);
CustomModal.displayName = 'CustomModal';
export default CustomModal;
