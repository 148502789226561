import React, { useEffect, useState } from 'react';

import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import {
  Card,
  Typography,
  Box,
} from '@mui/material';

import { getLastNodes } from '../../services/get.service';
import { INode } from '../../types/node';
import HierarchicaleNodeList from '../utils/hierarchical-node-list/hierarchical-node-list';

// type FeedProps = {}

const Feed: React.FC = () => {
  const [feedNodes, setFeedNodes] = useState<INode[]>([]);

  useEffect(() => {
    let active = true;

    const loadFeedNodes = async () => {
      const lastNodes = await getLastNodes(25);
      if (active && lastNodes.length > 0) {
        setFeedNodes(lastNodes);
      }
    };

    loadFeedNodes();

    return () => {
      active = false;
    };
  }, [setFeedNodes]);

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 1 }}>
        <DynamicFeedIcon fontSize="large" sx={{ mr: 1 }} />
        <Typography variant="h3" component="h3">
          Last updated
        </Typography>
      </Box>
      <Card>
        <HierarchicaleNodeList nodes={feedNodes} editMode={false} />
        {feedNodes.length === 0 && (
        <Typography variant="body1" component="p">
          There are no nodes in the workspace
        </Typography>
        )}
      </Card>
    </Box>
  );
};

export default Feed;
