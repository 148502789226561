import { IBox } from '../../../types/box';
import { INode } from '../../../types/node';

export const getNodeAndLabelArray = (inNodes: INode[]): { labelsArr: string[], nodesArr: INode[][] } => {
  const nodeLabelMap: Map<string, INode[]> = new Map();
  inNodes.forEach((node: INode) => nodeLabelMap.set(node.label, [...(nodeLabelMap.get(node.label) || []), node]));
  const labelsArr = Array.from(nodeLabelMap.keys());
  const nodesArr = Array.from(nodeLabelMap.values());
  return { labelsArr, nodesArr };
};

export const removeBoxNodes = (labelList: string[], nodesList: INode[][]): {
  labelList: string[],
  nodesList: INode[][],
  boxNodes: IBox[]
} => {
  const boxIndex = labelList.indexOf('box');
  let boxNodes: IBox[] = [];
  if (boxIndex !== -1) {
    labelList.splice(boxIndex, 1);
    boxNodes = nodesList.splice(boxIndex, 1)[0] as IBox[];
  }
  return { labelList, nodesList, boxNodes };
};

export const removeNodeFromLists = (node: INode, labelsArr: string[], nodesArr: INode[][]) => {
  const lIndex = labelsArr.indexOf(node.label);
  const nIndex = nodesArr[lIndex].findIndex((n) => n.id === node.id);
  const nrNodes = nodesArr[lIndex].length;

  if (nrNodes > 1) {
    nodesArr[lIndex].splice(nIndex, 1);
  } else {
    nodesArr.splice(lIndex, 1);
    labelsArr.splice(lIndex, 1);
  }
  return { labelsArr, nodesArr };
};
