import React, { useState, useEffect } from 'react';

import {
  Box,
  List,
} from '@mui/material';

import { INode } from '../../../types/node';
import NodeListItem from '../node-list/node-list-item';

type HierarchicaleNodeListProps = {
  nodes: INode[];
  editMode: boolean;
  // eslint-disable-next-line react/require-default-props
  parentNode?: INode | undefined;
}

const HierarchicaleNodeList: React.FC<HierarchicaleNodeListProps> = (
  { nodes, editMode = false, parentNode = undefined },
) => {
  const [nodesList, setNodesList] = useState<INode[]>(nodes);

  useEffect(() => {
    setNodesList(nodes);
  }, [setNodesList, nodes]);

  const onDeleteConnection = (node: INode) => {
    const position = nodes.findIndex((n) => n.id === node.id);
    const newNodes = [...nodes].splice(position, 1);
    setNodesList(newNodes);
  };

  return (
    <List
      aria-labelledby="nested-list-subheader"
      sx={{ width: '100%' }}
    >
      {nodesList.map((node) => (
        <Box key={node.id}>
          <NodeListItem
            node={node}
            secondary="label"
            onDeleteConnection={onDeleteConnection}
            parentNode={parentNode}
            editMode={editMode}
          />
        </Box>
      ))}
    </List>
  );
};

export default HierarchicaleNodeList;
