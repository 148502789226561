import React, { useContext, useState } from 'react';

import { Container, Box, Tab, Tabs } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import IntegrationManagement from './components/integration-management/integration-management';
import LicenceManager from './components/licence-management/licence-manager';
import CreateUser from './components/user-management/create-user';
import UserList from './components/user-management/user-list';
import AuthContext from '../../context/auth-context';
import { getAllUsers } from '../../services/user.service';
import { IUser } from '../../types/user';

interface LinkTabProps {
  label: string;
  href: string;
  value: number;
}

const LinkTab = ({ label, href, value }: LinkTabProps) => (
  <Tab
    to={href}
    component={Link}
    value={value}
    {...{ label }}
  />
);

interface TabPanelProps {
  children: React.ReactNode | undefined;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
            // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </Box>
  );
};

const getValue = (tab: string): number => {
  if (tab === '/settings/user-management') return 0;
  if (tab === '/settings/plan') return 1;
  if (tab === '/settings/integrations' || tab.includes('/settings/integrations')) return 2;
  return 0;
};

const SettingsTabs: React.FC = () => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    const loadUsers = async () => {
      const usersTemp = await getAllUsers();
      setUsers(usersTemp || []);
    };
    const initValue = getValue(pathname);
    setValue(initValue);
    loadUsers();
  }, [setUsers, setValue, pathname]);

  const addUser = (inUser: IUser) => {
    setUsers([...users, inUser]);
  };

  const editUser = (inUser: IUser) => {
    const newUsers = users.map((u) => (u.id === inUser.id ? inUser : u));
    setUsers(newUsers);
  };

  return (
    <Container>
      {user?.role === 'admin' && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value}>
              <LinkTab label="User Management" href="/settings/user-management" value={0} />
              <LinkTab label="Plan" href="/settings/plan" value={1} />
              <LinkTab label="Integrations" href="/settings/integrations/:integration" value={2} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CreateUser addUser={addUser} />
            <UserList users={users} editUser={editUser} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LicenceManager users={users} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <IntegrationManagement />
          </TabPanel>
        </>
      )}
    </Container>
  );
};

export default SettingsTabs;
