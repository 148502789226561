import { CognitoUser } from 'amazon-cognito-identity-js';
import axios from 'axios';

import { getConfig } from './utils';
import userPool from '../config/cognitoConfig';
import { IUser } from '../types/user';

const baseUrl = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : '';

export const getAllUsers = async (): Promise<IUser[] | undefined> => {
  const config = await getConfig();
  const user = (await axios.get(`${baseUrl}/users/all`, config)).data as
    | IUser[]
    | undefined;
  return user;
};

export const createUser = async (
  user: Partial<IUser>,
): Promise<IUser | undefined> => {
  const { email, password, ...otherUserDetails } = user;
  const userDataForEndpoint = { email, ...otherUserDetails };

  if (!email || !password) {
    console.error('Email and password are required');
    return undefined;
  }

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, [], null, async (err, result) => {
      if (err) {
        console.error(err.message || JSON.stringify(err));
        reject(new Error(err.message || JSON.stringify(err)));
        return;
      }

      try {
        const config = await getConfig();
        const createdUser = (
          await axios.post(`${baseUrl}/users`, userDataForEndpoint, config)
        ).data as IUser;
        resolve(createdUser);
      } catch (error) {
        console.error('Error creating user:', error);

        reject(new Error('Error creating user in backend'));
      }
    });
  });
};

export const updateUser = async (
  id: string,
  user: Partial<IUser>,
): Promise<IUser | undefined> => {
  const config = await getConfig();
  const updatedUser = (
    await axios.patch(`${baseUrl}/users/${id}`, user, config)
  ).data as IUser;
  return updatedUser;
};

export const changeUserPassword = async (
  cognitoUser: CognitoUser,
  oldPassword: string,
  newPassword: string,
): Promise<string> => new Promise((resolve, reject) => {
  try {
    cognitoUser.getSession((err: Error | null, session: any) => {
      if (err) {
        console.error(err);
        reject(err);
        return;
      }
      if (session.isValid()) {
        cognitoUser.changePassword(
          oldPassword,
          newPassword,
          (err_: Error | null) => {
            if (err_) {
              console.error('Error changing password:', err_);
              reject(err_);
            } else {
              resolve('Password changed successfully');
            }
          },
        );
      } else {
        reject(new Error('Session is not valid'));
      }
    });
  } catch (err) {
    reject(err);
  }
});

export const deleteUser = async (id: string): Promise<boolean> => {
  const config = await getConfig();
  await axios.delete(`${baseUrl}/users/${id}`, config);
  return true;
};
