import React, { createContext, FC, useState } from 'react';

// Define Context Type
interface ISearchBarContext {
  searchTerm: string,
  setSearchTerm: (searchTerm: string) => void,
}

// Provider
const SearchBarContext = createContext<ISearchBarContext>({} as ISearchBarContext);

export const SearchBarProvider: FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SearchBarContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchBarContext.Provider>
  );
};

// Export to app
export default SearchBarContext;
