import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, styled, Modal, ModalProps, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { deleteNode } from '../../../services/delete.service';
import { INode } from '../../../types/node';

const style = (isLargeScreen: boolean) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isLargeScreen ? '600px' : '90%',
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type DeleteNodeType = {
  node: INode;
  children: React.ReactNode;
}

const DeleteNode: React.FC<DeleteNodeType> = ({ node, children }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<undefined | string>(undefined);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const success = await deleteNode(node.id);
    if (success) {
      setTimeout(() => {
        setIsLoading(false);
        setError(undefined);
        handleClose();
        navigate('/');
      }, 500);
    } else {
      setError('Something went wrong, please contact an administrator!');
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box onClick={handleOpen}>
        { children }
      </Box>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style(isLargeScreen)}>
          <Typography id="modal-title" variant="h4" component="h2">
            Delete
            {' '}
            &quot;
            { node.name }
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Do you want to delete
            {' '}
            &quot;
            { node.name }
            ?
          </Typography>

          {error
            && (
            <Alert severity="error">
              {error}
            </Alert>
            )}

          <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              onClick={onSubmit}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </ModalStyle>
    </Box>
  );
};

export default DeleteNode;
