import React from 'react';

import { LoadingButton } from '@mui/lab';
import { TextField, Button } from '@mui/material';
import { Box } from '@mui/system';

export type Credentials = {
  email: string;
  password: string;
  baseUrl: string;
};

type CredentialsFormProps = {
  onSubmit: (credentials: Credentials) => void;
  onCancel: () => void;
  isLoading: boolean;
};

const CredentialsForm: React.FC<CredentialsFormProps> = ({ onSubmit, onCancel, isLoading }) => {
  const [credentials, setCredentials] = React.useState<Credentials>({
    email: '',
    password: '',
    baseUrl: '',
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(credentials);
  };

  const handleCancel = () => {
    setCredentials({
      email: '',
      password: '',
      baseUrl: '',
    });
    onCancel();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Email"
        name="email"
        value={credentials.email}
        onChange={handleChange}
        type="email"
        required
        disabled={isLoading}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Password"
        name="password"
        value={credentials.password}
        onChange={handleChange}
        type="password"
        required
        disabled={isLoading}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Domain"
        name="baseUrl"
        value={credentials.baseUrl}
        onChange={handleChange}
        type="url"
        required
        disabled={isLoading}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={handleCancel} sx={{ mr: 1 }}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
        >
          Next
        </LoadingButton>
      </Box>
    </form>
  );
};

export default CredentialsForm;
