import React, { useState, useContext } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import AuthContext from '../../../context/auth-context';
import { updateUser } from '../../../services/user.service';
import { IUser } from '../../../types/user';

export type UpdateUserFormType = {
  firstname: string;
  lastname: string;
};

type EditProfileType = {
  user: IUser;
}

const EditProfile: React.FC<EditProfileType> = ({ user }) => {
  const { register, handleSubmit, reset } = useForm<UpdateUserFormType>();
  const { setUser } = useContext(AuthContext);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const toggleEditMode = () => {
    reset();
    setError(false);
    setEditMode(!editMode);
  };

  const onSubmit = async (data: UpdateUserFormType) => {
    setIsLoading(true);
    const updatedUser = await updateUser(user.id, data);
    if (updatedUser && updatedUser.id) {
      setUser(updatedUser);
      setTimeout(() => {
        setIsLoading(false);
        setError(false);
        setEditMode(!editMode);
      }, 2000);
    } else {
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <Typography variant="h6" gutterBottom>
        Personal data
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        required
        label="First name"
        type="text"
        defaultValue={user.firstname}
        error={error}
        InputProps={{ readOnly: !editMode }}
        helperText={error && 'Enter a valid first name'}
        {...register('firstname', { required: true })}
      />
      <TextField
        margin="normal"
        fullWidth
        required
        label="Last name"
        type="text"
        defaultValue={user.lastname}
        error={error}
        InputProps={{ readOnly: !editMode }}
        helperText={error && 'Enter a valid last name'}
        {...register('lastname', { required: true })}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Email"
        type="email"
        value={user.email}
        error={error}
        disabled={editMode}
        InputProps={{ readOnly: true }}
      />

      {error
              && (
              <Box sx={{ my: 2, color: 'error.main' }}>
                Check your input!
              </Box>
              )}

      <Box sx={{ pt: 2, justifyContent: 'end', display: 'flex' }}>
        {editMode ? (
          <>
            <Button onClick={toggleEditMode} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </>
        ) : (
          <Button
            variant="contained"
            onClick={toggleEditMode}
          >
            Edit
          </Button>
        )}

      </Box>
    </Box>
  );
};

export default EditProfile;
