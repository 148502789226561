import React, { FC, useContext, useState, useEffect } from 'react';

// Material UI
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import { LoadingButton } from '@mui/lab';
import { Button, Card, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// packages
import { FieldValues, useForm } from 'react-hook-form';
// custom
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import SSOForm from './sso-form';
import Icon from '../../assets/logo/icon.svg';
import AuthContext from '../../context/auth-context';
import useAuthCode from '../../hooks/useAuthCode';
import { getUser } from '../../services/auth.service';
import { handleAuthCode } from '../../services/aws.service';
import { IUser } from '../../types/user';
import LoadingSpinner from '../utils/loading-spinner/loading-spinner';

const FORGORT_PASSWORD_MAIL = `mailto:lukas.klassen@knowledge-in-a-box.de?
  cc=till.hoffmann@knowledge-in-a-box.de&subject=Passwort%20vergessen&body=
  Hallo%20Herr%20Kla%C3%9Fen%2C%0D%0A%0D%0ALeider%20habe%20ich%20mein%20Passwort
  %20vergessen.%0D%0A%0D%0AW%C3%A4re%20es%20m%C3%B6glich%2C%20dass%20Sie%20dieses
  %20zur%C3%BCcksetzen%3F%0D%0A%0D%0A%0D%0Afreundliche%20Gr%C3%BC%C3%9Fe%2C%0D%0A`;

const ACCESS_REQUEST = '/signup';

const Copyright = (props: {
  sx: {
    mt: number;
    mb: number;
  }
}) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {'Copyright © '}
    <Link color="inherit" href="https://knowledge-in-a-box.de/">
      Knowledge in a Box
    </Link>
    {' '}
    {new Date().getFullYear()}
    .
  </Typography>
);

type LoginFormType = {
  email: string;
  password: string;
  remember: boolean;
};

type LoginProps = {
  login: (email: string, password: string) => Promise<undefined | IUser>,
}

const Login: FC<LoginProps> = ({ login }) => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormType>();
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const { code, error: errorCode, errorDescription, clearCode, clearError } = useAuthCode();
  const [error, setError] = useState<boolean>();

  const onSubmit = async (data: FieldValues) => {
    clearError();
    setError(false);
    setIsLoading(true);
    const user = await login(data.email, data.password);
    setIsLoading(false);
    if (!user) {
      setError(true);
      return;
    }
    setUser(user);
    navigate('/box');
  };

  useEffect(() => {
    const generateTokens = async () => {
      if (code) {
        try {
          await handleAuthCode(code);
          clearCode();
          const user = await getUser();
          if (user) {
            setUser(user);
            navigate('/box');
          } else {
            setError(true);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    generateTokens();
    setError(errorCode !== null);
  }, [errorCode, code, navigate, setUser, clearCode]);

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <Card sx={{ px: 2, py: 2 }}>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ m: 1 }}>
            <img src={Icon} alt="Icon" />
          </Box>
          <Typography component="h5" variant="h5">
            Login
          </Typography>

          { code
            ? (
              <Box>
                <LoadingSpinner />
              </Box>
            ) : (
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  fullWidth
                  label="Email Address"
                  type="email"
                  autoComplete="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={errors.email && 'Provide a valid email'}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Entered value does not match email format',
                    },
                  })}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  error={!!errors.password}
                  helperText={errors.password && 'Please enter a password'}
                  {...register('password', { required: true })}
                />

                {error && (
                <Box sx={{ my: 2, color: 'error.main' }}>
                  { errorDescription || 'Check your email and password and try again.' }
                </Box>
                )}

                <LoadingButton
                  sx={{ mt: 3, mb: 2, width: '100%' }}
                  type="submit"
                  variant="contained"
                  loading={isLoading}
                >
                  Login
                </LoadingButton>
                <SSOForm>
                  <Button
                    sx={{ mt: 2, mb: 2, width: '100%' }}
                    type="button"
                    variant="outlined"
                    startIcon={<MicrosoftIcon />}
                  >
                    Microsoft (SSO)
                  </Button>
                </SSOForm>
                <Button
                  sx={{ mt: 2, mb: 2, width: '100%' }}
                  variant="text"
                  component={RouterLink}
                  to={ACCESS_REQUEST}
                >
                  Create workspace
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link component={RouterLink} to={FORGORT_PASSWORD_MAIL} variant="body2">
                      Forgot your password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Card>
    </Container>
  );
};

export default Login;
