import React, { useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, styled, Modal, ModalProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import { requestLicence } from '../../../../services/licence.service';
import { ILicence } from '../../../../types/licence';

const style = (isLargeScreen: boolean) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: isLargeScreen ? '600px' : '90%',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type LiceneceRequestType = {
  licence: ILicence;
  children: React.ReactNode;
}

const LicenceRequest: React.FC<LiceneceRequestType> = ({ licence, children }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const [success, setSuccess] = useState<undefined | string>(undefined);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await requestLicence(licence.id);
      if (response) {
        setSuccess('Your request has been sent!');
        setError(undefined);
        // set a timeout for the user to see the success message
        setTimeout(() => {
          setIsLoading(false);
          setSuccess(undefined);
          handleClose();
        }, 3000);
      } else {
        setError('Something went wrong');
        setIsLoading(false);
      }
    } catch (e: any) {
      setError(e.message);
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box onClick={handleOpen}>
        { children }
      </Box>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {success ? (
          <Box sx={style(isLargeScreen)}>
            <Typography id="modal-title" variant="h4" component="h2">
              {success}
            </Typography>
          </Box>
        ) : (
          <Box sx={style(isLargeScreen)}>
            <Typography id="modal-title" variant="h4" component="h2">
              Request a
              {' '}
              { licence.name }
              {' '}
              licence
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Do you want to request a
              {' '}
              { licence.name }
              {' '}
              licence?
              This will be reviewed by our team and you will be notified via email.
              You can cancel your request at any time.
              If you have any questions, please contact us at info@knowledge-in-a-box.de.
            </Typography>
            <Box>
              {
                                licence.features.map((feature) => (
                                  <Box key={feature} sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                                    <CheckCircleOutlineIcon sx={{ mr: 1 }} />
                                    <Typography variant="body1" component="div">
                                      { feature }
                                    </Typography>
                                  </Box>
                                ))
                            }
            </Box>
            { licence.name === 'premium' && (
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              This will cost
              {' '}
              { licence.price }
            </Typography>
            )}

            {error
            && (
            <Alert sx={{ my: 2 }} severity="error">
              {error}
            </Alert>
            )}

            <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
              <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                onClick={onSubmit}
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SendIcon />}
              >
                Send request
              </LoadingButton>
            </Box>
          </Box>
        )}
      </ModalStyle>
    </Box>
  );
};

export default LicenceRequest;
