import React, { FC } from 'react';

import FeedbackIcon from '@mui/icons-material/Feedback';
import InventoryIcon from '@mui/icons-material/Inventory';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import { Drawer, Link, styled, Avatar, Box, Typography, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import NavSection from './components/nav-section';
import { NavMenuItem } from './types/nav-menu-options';
import Logo from '../../assets/logo/logo.svg';
import { IUser } from '../../types/user';
import { MHidden } from '../utils/hide-responsive/index';
// ------------------------------------------------------------------------
const DRAWER_WIDTH = 280;

const RootStyle = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
}));

// ------------------------------------------------------------------------

const MENU_OPTIONS: NavMenuItem[] = [
  { label: 'Search', icon: <SearchIcon />, path: '/search', guard: '' },
  { label: 'Dashboard', icon: <InventoryIcon />, path: '/box', guard: '' },
  { label: 'Profile', icon: <PersonIcon />, path: '/profile', guard: '' },
  { label: 'Settings', icon: <SettingsIcon />, path: '/settings', guard: 'admin' },
  { label: 'Log out', icon: <LogoutIcon />, path: '/logout', guard: '' },
];

// ------------------------------------------------------------------------

const LOWER_MENU_OPTIONS: NavMenuItem[] = [
  {
    label: 'Feedback',
    icon: <FeedbackIcon />,
    // eslint-disable-next-line max-len
    path: 'https://forms.office.com/e/ifyPsSrzpR',
    guard: '',
  },
];

// ------------------------------------------------------------------------

type SidebarProps = {
  sidebarOpen: boolean;
  closeSidebar: () => void;
  user: IUser | undefined;
}

const Sidebar: FC<SidebarProps> = ({ sidebarOpen, closeSidebar, user }) => {
  const sidebarContent = (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{ display: 'inline-flex' }}
        >
          <img src={Logo} alt="Logo" />
        </Box>
      </Box>

      {user && (
        <>
          <Box sx={{ mb: 5, mx: 2.5 }}>
            <Link underline="none" component={RouterLink} to="/profile">
              <AccountStyle>
                <Avatar src={user.imageUrl} alt="photoURL" />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: 'text.primary' }}
                  >
                    {user.displayName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary' }}
                    noWrap
                  >
                    {user.email}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
          </Box>
          <Box sx={{ height: '100%' }}>
            <NavSection menuItems={MENU_OPTIONS} />
          </Box>
          <Box sx={{ position: 'absolute', bottom: '1em', width: '100%' }}>
            <NavSection menuItems={LOWER_MENU_OPTIONS} />
          </Box>
        </>
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={sidebarOpen}
          onClose={closeSidebar}
          PaperProps={{ sx: { width: DRAWER_WIDTH } }}
        >
          {sidebarContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}
        >
          {sidebarContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
};

export default Sidebar;
