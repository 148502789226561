import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  ListItem,
  IconButton,
  Tooltip,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import DeleteConnection from './delete-connection';
import { INode } from '../../../types/node';
import NodeListItemIcon from '../list-item-icon/node-list-item-icon';

type NodeListItemProps = {
  node: INode;
  onDeleteConnection: (node: INode, parentNode: INode) => void;
  editMode: boolean;
  secondary?: 'label' | 'none';
  parentNode?: INode | undefined;
}

const NodeListItem: React.FC<NodeListItemProps> = (
  { node, editMode = false, onDeleteConnection, secondary, parentNode = undefined },
) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/node?id=${node.id}`, { state: { node } });
  };

  const secondaryLabel = secondary === 'label' ? node.label : '';

  return (
    <ListItem
      disablePadding
      secondaryAction={parentNode && editMode && (
        <DeleteConnection node={node} parentNode={parentNode} onDeleteConnection={onDeleteConnection}>
          <Tooltip title="Remove connection">
            <IconButton aria-label="remove connection">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DeleteConnection>
      )}
    >
      <ListItemButton {...!editMode && { onClick }}>
        <NodeListItemIcon node={node} />
        <ListItemText primary={node.name} secondary={secondaryLabel} />
      </ListItemButton>
    </ListItem>
  );
};

NodeListItem.defaultProps = { secondary: 'none', parentNode: undefined };
export default NodeListItem;
