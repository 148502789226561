import axios from 'axios';

import { getConfig } from './utils';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const deleteNode = async (nodeId: number): Promise<boolean> => {
  try {
    const config = await getConfig();
    const result = (await axios.delete(`${baseUrl}/delete/node/${nodeId}`, config)).data;
    return result;
  } catch (error) {
    // No Cookie set
    return false;
  }
};

export const deleteRelation = async (firstNodeId: number, secondNodeId: number): Promise<boolean> => {
  try {
    const config = await getConfig();
    const result = (await axios.delete(
      `${baseUrl}/delete/relation/${firstNodeId}/${secondNodeId}`,
      config,
    )).data;
    return result;
  } catch (error) {
    // No Cookie set
    return false;
  }
};

export const deleteOrganisationFavorite = async (nodeId: number): Promise<boolean> => {
  try {
    const config = await getConfig();
    const result = (await axios.delete(
      `${baseUrl}/delete/relation/orgFavorite/${nodeId}`,
      config,
    )).data;
    return result;
  } catch (error) {
    // No Cookie set
    return false;
  }
};
