import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

const useAuthCode = () => {
  const [code, setCode] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [errorDescription, setErrorDescription] = useState<string | null>(null);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeParam = searchParams.get('code');
    const errorParam = searchParams.get('error');
    const errorDescriptionParam = searchParams.get('error_description');

    if (codeParam) {
      setCode(codeParam);
    } else if (errorParam && errorDescriptionParam) {
      setError(errorParam);
      setErrorDescription(errorDescriptionParam);
    }
  }, [location.search]);

  const clearCode = () => {
    setCode(null);
    // clear the code from the url
    window.history.replaceState({}, document.title, '/');
  };

  const clearError = () => {
    setError(null);
    setErrorDescription(null);
    // clear the error from the url
    window.history.replaceState({}, document.title, '/');
  };

  return { code, error, errorDescription, clearCode, clearError };
};

export default useAuthCode;
