import React from 'react';

import {
  Card,
  List,
  ListSubheader,
} from '@mui/material';

import NodeListItem from './node-list-item';
import { INode } from '../../../types/node';
import { transformLabel } from '../helper';

type NodeSubListProps = {
  nodes: INode[];
  label: string;
  wrapper: boolean;
  editMode: boolean;
  onDeleteConnection: (node: INode, parentNode: INode) => void;
  // eslint-disable-next-line react/require-default-props
  parentNode?: INode | undefined;
};

const NodeSubList: React.FC<NodeSubListProps> = (
  { nodes, editMode, wrapper = true, label, onDeleteConnection, parentNode = undefined },
) => {
  const content = (
    <List>
      <ListSubheader component="div" id="nested-list-subheader">
        { transformLabel(label) }
      </ListSubheader>
      { nodes.map((n) => (
        <NodeListItem
          key={n.id}
          node={n}
          parentNode={parentNode}
          editMode={editMode}
          onDeleteConnection={onDeleteConnection}
        />
      ))}
    </List>
  );

  return wrapper ? (
    <Card>
      {content}
    </Card>
  ) : content;
};

export default NodeSubList;
