import axios from 'axios';

import { INode } from '../../types/node';

export const nodeType = (node: INode): string => {
  if (node.firstname && node.lastname && node.sex) {
    return 'person';
  }
  return 'node';
};

// TODO not working properly!
export const validURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*))' // domain name
    + '((\\.)+[a-z]{2,})?|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );
  return !!pattern.test(str);
};

export const sameUrls = (first: string, second: string) => {
  if (!first || !second) return false;
  try {
    const firstUrl = new URL(first);
    const secondUrl = new URL(second);
    return (
      firstUrl.hostname === secondUrl.hostname
      && firstUrl.pathname === secondUrl.pathname
    );
  } catch (e) {
    return false;
  }
};

export const transformLabel = (label: string) => `${label.charAt(0).toUpperCase() + label.slice(1)}`;

export const getInfoFromUrl = async (url: string): Promise<string> => {
  try {
    const result = await axios.get(`https://title.mihir.ch/${url}`);
    return result.data;
  } catch (e) {
    return '';
  }
};

export const getFaviconFromUrl = (u: string): string => `https://www.google.com/s2/favicons?domain=${u}&sz=256`;
