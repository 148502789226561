import axios from 'axios';

import { getConfig } from './utils';
import { INode } from '../types/node';
import { IImportWebsite } from '../types/website';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const connectNodes = async (fromNode: number, toNodes: number[]): Promise<INode[] | undefined> => {
  const body = {
    fromId: fromNode,
    toIds: toNodes,
  };
  try {
    const config = await getConfig();

    const linkedNodes = (await axios.post(`${baseUrl}/connect/oneToMultiple`, body, config)).data;
    return linkedNodes;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const addPersFavorite = async (nodeId: number): Promise<INode | undefined> => {
  const body = { nodeId };
  try {
    const config = await getConfig();

    const favNode = (await axios.post(
      `${baseUrl}/connect/favorite/personal`,
      body,
      config,
    )).data as INode;
    return favNode;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const addOrgFavorite = async (nodeId: number): Promise<INode | undefined> => {
  const body = { nodeId };
  try {
    const config = await getConfig();

    const favNode = (await axios.post(
      `${baseUrl}/connect/favorite/organistaion`,
      body,
      config,
    )).data as INode;
    return favNode;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const createOneToMultipleWithUrl = async (websites: IImportWebsite[]): Promise<any> => {
  try {
    const config = await getConfig();

    const res = (await axios.post(
      `${baseUrl}/connect/oneToMultipleWithUrl`,
      websites,
      config,
    )).data as INode;
    return res;
  } catch (error: any) {
    throw Error(error);
  }
};
