/* eslint-disable camelcase */
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  IAuthenticationDetailsData,
} from 'amazon-cognito-identity-js';
import axios from 'axios';

import userPool, { AvailProvider, GrantType, baseProvider } from '../config/cognitoConfig';
import { IAWSUser, TokenData } from '../types/aws';

const getLocalStoreToken = (): string | undefined => localStorage.getItem('accessToken') || undefined;
const getRefreshToken = (): string | undefined => localStorage.getItem('refreshToken') || undefined;
const getExpiresAt = (): number => {
  const expiresAt = localStorage.getItem('expiresAt');
  return expiresAt ? parseInt(expiresAt, 10) : 0;
};

export const areRequiredTokensInStore = (): boolean => {
  const accessToken = getLocalStoreToken();
  const refreshToken = getRefreshToken();
  return !!accessToken && !!refreshToken;
};

const setTokenData = async (data: TokenData): Promise<void> => {
  // extract the access token from the response
  const {
    access_token: accessToken,
    expires_in: expiresIn,
    id_token: idToken,
    refresh_token: refreshToken,
  } = data;
  // add expiresAt to the current time to get the expiration time minus 5 minutes
  const expiresAt = new Date().getTime() + expiresIn * 1000; // - 300000;

  // store the access token in local storage
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('expiresAt', expiresAt.toString());
  localStorage.setItem('idToken', idToken);

  // store the refresh token only if it exists. If it doesn't exist, it means the token got refreshed.
  // After refresh, the refresh token is not returned.
  if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
};

const revokeToken = async (): Promise<void> => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return;
  }
  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', baseProvider.ClientId);
  urlencoded.append('token', refreshToken);

  await axios.post(
    baseProvider.revokeTokenEndpoint,
    urlencoded,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
};

export const awsLogout = async (): Promise<void> => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.globalSignOut({
      onSuccess: () => {
        console.log('Logged out');
      },
      onFailure: (err) => {
        console.error(err);
      },
    });
  } else {
    await revokeToken();
  }
  localStorage.clear();
};

const refreshAccessToken = async (refreshToken: string): Promise<string> => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', 'refresh_token');
  urlencoded.append('client_id', baseProvider.ClientId);
  urlencoded.append('refresh_token', refreshToken);

  const response = await axios.post(
    baseProvider.tokenEndpoint,
    urlencoded,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

  if (response.data.error === 'invalid_grant') {
    // refresh token is invalid, logout
    await awsLogout();
    return undefined;
  }
  setTokenData(response.data);
  return response.data.access_token;
};

export const awsGetToken = async (): Promise<string | undefined> => {
  const accessToken = getLocalStoreToken();
  if (!accessToken) return undefined;

  const expiresAt = getExpiresAt();

  // check if the token is expired
  if (expiresAt && new Date().getTime() > expiresAt) {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      // refresh the token
      const newAccessToken = await refreshAccessToken(refreshToken);
      return newAccessToken;
    }
    // No refresh token, need to login again
    awsLogout();
    return undefined;
  }

  return accessToken;
};

export const startMicrosoftLogin = (provider: AvailProvider): string => {
  const uri = new URL(baseProvider.authorizationEndpoint);
  uri.searchParams.append('identity_provider', provider);
  uri.searchParams.append('redirect_uri', baseProvider.redirectSignIn);
  uri.searchParams.append('response_type', baseProvider.responseType);
  uri.searchParams.append('client_id', baseProvider.ClientId);

  // open new window with login url
  window.location.href = uri.href;
  return uri.href;
};

export const awsGetUser = async (): Promise<undefined | IAWSUser> => {
  // first get the access token, if it doesn't exist, user is not logged in
  const accessToken = await awsGetToken();
  if (!accessToken) return undefined;

  // check if the user is logged in with cognito directly
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    const session = await new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.getSession((err, sess) => {
        if (err) {
          reject(err);
        }
        resolve(sess);
      });
    });
    return {
      id: cognitoUser.getUsername(),
      email: session.getIdToken().payload.email,
      username: cognitoUser.getUsername(),
    };
  }

  // if the user is not logged in with cognito, get the user data from the user endpoint
  // use axios to send the request to the user endpoint
  const response = await axios.get(
    baseProvider.userInfoEndpoint,
    {
      headers: {
        'Content-Type': 'application/x-amz-json-1.1',
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.status !== 200) {
    return response.data;
  }
  const { sub, email, username } = response.data;
  return { id: sub, email, username };
};

export const handleAuthCode = async (code: string): Promise<undefined | IAWSUser> => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('grant_type', GrantType.Authorization);
  urlencoded.append('client_id', baseProvider.ClientId);
  urlencoded.append('code', code);
  urlencoded.append('redirect_uri', baseProvider.redirectSignIn);

  // use axios to send the request to the token endpoint
  const response = await axios.post(
    baseProvider.tokenEndpoint,
    urlencoded,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  setTokenData(response.data);

  // get the user data from the user endpoint
  const user = await awsGetUser();
  return user;
};

export const awsLogin = async (email: string, password: string): Promise<undefined | IAWSUser> => {
  const userName = email;
  const usrData: IAuthenticationDetailsData = {
    Username: userName,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(usrData);

  const cognitoUser = new CognitoUser({
    Username: userName,
    Pool: userPool,
  });

  const session = await new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (sess) => {
        resolve(sess);
      },
      onFailure: (err) => {
        reject(err);
      },
      newPasswordRequired: () => {
        reject(new Error('Password change required'));
      },
    });
  });

  const access_token = session.getAccessToken().getJwtToken();
  const refresh_token = session.getRefreshToken().getToken();
  const id_token = session.getIdToken().getJwtToken();
  const expires_in = session.getAccessToken().getExpiration() - new Date().getTime();

  setTokenData({ access_token, refresh_token, id_token, expires_in });
  return awsGetUser();
};

export const awsSignUp = async (email: string, password: string)
  : Promise<undefined | IAWSUser> => new Promise<IAWSUser>((resolve, reject) => {
    userPool.signUp(
      email,
      password,
      [],
      null,
      async (err) => {
        if (err) {
          return reject(err); // Reject the promise on error
        }
        try {
          const user = await awsLogin(email, password);
          return resolve(user);
        } catch (postError) {
          return reject(err);
        }
      },
    );
  });
