import React, { createContext, FC, useState } from 'react';

// Define Context Type
interface IFavContext {
  persFav: number[],
  orgFav: number[],
  setPersFav: (nodes: number[]) => void,
  setOrgFav: (nodes: number[]) => void,
  togglePersFav: (node: number) => void,
  toggleOrgFav: (node: number) => void,
  isPersFav: (nodeId: number) => boolean,
  isOrgFav: (nodeId: number) => boolean
}

// Provider
const FavContext = createContext<IFavContext>({} as IFavContext);

export const FavProvider: FC = ({ children }) => {
  const [persFav, setPersFav] = useState<number[]>([]);
  const [orgFav, setOrgFav] = useState<number[]>([]);

  const toggleFav = (node: number, arr: number[]) => {
    const nodeIndex = arr.findIndex((b) => b === node);
    if (nodeIndex === -1) {
      arr.push(node);
      setPersFav(persFav);
    } else {
      arr.splice(nodeIndex, 1);
    }
    return arr;
  };
  const togglePersFav = (node: number) => {
    setPersFav([...toggleFav(node, persFav)]);
  };
  const toggleOrgFav = (node: number) => {
    setOrgFav([...toggleFav(node, orgFav)]);
  };

  const isFav = (id: number, arr: number[]) => arr.findIndex((b) => b === id) !== -1;
  const isPersFav = (nodeId: number) => isFav(nodeId, persFav);
  const isOrgFav = (nodeId: number) => isFav(nodeId, orgFav);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FavContext.Provider value={{
      persFav,
      orgFav,
      setPersFav,
      setOrgFav,
      togglePersFav,
      toggleOrgFav,
      isPersFav,
      isOrgFav,
    }}
    >
      {children}
    </FavContext.Provider>
  );
};

// Export to app
export default FavContext;
