import React, { useContext, useEffect, useState } from 'react';

import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import {
  Typography,
  Box,
  Stack,
  Grid,
} from '@mui/material';
import { Container } from '@mui/system';

import Feed from './feed';
import FavContext from '../../context/fav-context';
import { getPersFavorites, getOrgFavorites } from '../../services/get.service';
import { INode } from '../../types/node';
import NodeList from '../utils/node-list/node-list';

// type BoxListProps = {}

const Dashboard: React.FC = () => {
  const [persFavorites, setPersFavorites] = useState<INode[]>([]);
  const [orgFavorites, setOrgFavorites] = useState<INode[]>([]);

  const { setPersFav, setOrgFav } = useContext(FavContext);

  useEffect(() => {
    let active = true;

    const loadPersFavorites = async () => {
      const favoriteNodes = await getPersFavorites();
      if (active && favoriteNodes.length > 0) {
        setPersFavorites(favoriteNodes);
        setPersFav(favoriteNodes.map((n) => n.id));
      }
    };

    const loadOrgFavorites = async () => {
      const favoriteNodes = await getOrgFavorites();
      if (active && favoriteNodes.length > 0) {
        setOrgFavorites(favoriteNodes);
        setOrgFav(favoriteNodes.map((n) => n.id));
      }
    };

    loadPersFavorites();
    loadOrgFavorites();

    return () => { active = false; };
  }, [setOrgFav, setPersFav]);

  const persFavoritPart = (
    <Box>
      <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
        <PersonIcon fontSize="large" sx={{ mr: 1 }} />
        <Typography variant="h3" component="h3">
          Your favorites
        </Typography>
      </Box>
      <NodeList nodes={persFavorites} editMode={false} wrapper fancyBox />
      {persFavorites.length === 0 && (
        <Typography variant="body1" component="p">
          There are no personal favorites yet
        </Typography>
      )}
    </Box>
  );

  const orgFavoritPart = (
    <Box>
      <Box sx={{ display: 'flex', mb: 1, alignItems: 'center' }}>
        <GroupsIcon fontSize="large" sx={{ mr: 1 }} />
        <Typography variant="h3" component="h3">
          Organisation favorites
        </Typography>
      </Box>
      <NodeList nodes={orgFavorites} editMode={false} wrapper fancyBox />
      {orgFavorites.length === 0 && (
        <Typography variant="body1" component="p">
          There are no organistaion favorites yet
        </Typography>
      )}
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <Typography variant="h2" component="h2" sx={{ mb: 4 }}>
        Dashboard
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Stack
            spacing={6}
          >
            { persFavoritPart }
            { orgFavoritPart }
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Feed />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
