import React, { createContext, FC, useState } from 'react';

import { IBox } from '../types/box';
import { INode } from '../types/node';

// Define Context Type
interface IBoxContext {
  boxes: IBox[],
  setBoxes: (boxes: IBox[]) => void,
  setBoxProperties: (box: IBox, nodes: INode[]) => void,
  addNewBox: (box: IBox) => void,
  getBoxWithId: (boxId: number) => IBox | undefined
}

// Provider
const BoxContext = createContext<IBoxContext>({} as IBoxContext);

export const BoxProvider: FC = ({ children }) => {
  const [boxes, setBoxes] = useState<IBox[]>([]);

  const setBoxProperties = (box: IBox, nodes: INode[]) => {
    let boxIndex = boxes.findIndex((b) => b.id === box.id);
    if (boxIndex === -1) {
      const boxLength = boxes.push(box);
      setBoxes(boxes);
      boxIndex = boxLength - 1;
    }
    boxes[boxIndex].nodes = nodes;
    setBoxes([...boxes]);
  };

  const addNewBox = (box: IBox) => {
    setBoxes([...boxes, box]);
  };

  const getBoxWithId = (id: number) => boxes.find((box) => box.id === id);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BoxContext.Provider value={{ boxes, setBoxes, setBoxProperties, addNewBox, getBoxWithId }}>
      {children}
    </BoxContext.Provider>
  );
};

// Export to app
export default BoxContext;
