import { Breakpoint, useMediaQuery, Theme } from '@mui/material';

type MHiddenProps = {
  children: any,
  width: 'xsDown'|
    'smDown'|
    'mdDown'|
    'lgDown'|
    'xlDown'|
    'xsUp'|
    'smUp'|
    'mdUp'|
    'lgUp'|
    'xlUp'
};

const MHidden = ({ width, children }: MHiddenProps) => {
  const breakpoint = width.substring(0, 2);

  const hiddenDown = useMediaQuery((theme) => (theme as Theme).breakpoints.down(breakpoint as Breakpoint));
  const hiddenUp = useMediaQuery((theme) => (theme as Theme).breakpoints.up(breakpoint as Breakpoint));

  if (width.includes('Down')) {
    return hiddenDown ? null : children;
  }

  if (width.includes('Up')) {
    return hiddenUp ? null : children;
  }

  return null;
};

export default MHidden;
