import React, { useContext, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  Card,
  Typography,
  SvgIcon,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BoxSvg } from '../../../assets/icons/box.svg';
import AuthContext from '../../../context/auth-context';
import BoxContext from '../../../context/box-context';
import { getNodeWithId } from '../../../services/get.service';
import { IBox } from '../../../types/box';
import { INode } from '../../../types/node';
import LoadingSpinner from '../loading-spinner/loading-spinner';
import NodeList from '../node-list/node-list';
import PersonalFavoriteButton from '../personal-favorite-button/personal-favorite-button';

type BoxListItemProps = {
  box: IBox
}

const BoxListItem: React.FC<BoxListItemProps> = ({ box }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { setBoxProperties } = useContext(BoxContext);
  const [relatedNodes, setRelatedNodes] = useState<INode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const toggle = async (event: React.SyntheticEvent<Element, Event>, expanded: boolean) => {
    setOpen(expanded);
    if ((!box.documents || box.documents.length === 0)
      || !box.persons || box.persons.length === 0) {
      setLoading(true);
      const node = await getNodeWithId(box.id);
      if (!node || !node.nodes) return;
      setRelatedNodes(node.nodes);
      setBoxProperties(box, node.nodes);
      setLoading(false);
    }
  };

  const openBoxPage = () => {
    navigate(`/node?id=${box.id}`, { state: { box } });
  };

  return (
    <Card>
      <Accordion onChange={toggle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={`box-header-${box.id}`}
        >

          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <SvgIcon color="primary" fontSize="large">
              <BoxSvg />
            </SvgIcon>
            <Typography sx={{ pl: '1em' }}>{box.name}</Typography>

          </Box>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {box.description}
          </Typography>
          <Box sx={{ textAlign: 'end', pt: 1 }}>
            <Button
              startIcon={<OpenInNewIcon />}
              variant="text"
              onClick={openBoxPage}
            >
              Details
            </Button>
            {user && <PersonalFavoriteButton user={user} nodeId={box.id} />}
          </Box>

          {!loading && open
            ? (
              <Box>
                <NodeList nodes={relatedNodes} wrapper={false} fancyBox={false} breakpoints={false} />
              </Box>
            )
            : (
              <Box sx={{ py: '2em' }}>
                <LoadingSpinner />
              </Box>
            )}
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default BoxListItem;
