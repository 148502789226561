import React, { FC, useRef, useState } from 'react';

// material
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton, Icon } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// components
import MenuPopover from './MenuPopover';
import { IUser } from '../../../types/user';
import { AccountMenuItem } from '../types/account-menu-options';

type AccountPopoverType = {
  menuOptions: AccountMenuItem[],
  user: IUser
}

const AccountPopover: FC<AccountPopoverType> = ({ menuOptions, user }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src={user.imageUrl} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {`${user.firstname} ${user.lastname}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {menuOptions.map((option) => {
          if (option.guard === user.role || option.guard === '') {
            return (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{
                  typography: 'body2',
                  py: 1,
                  px: 2.5,
                  ':hover': {
                    bgcolor: (theme) => (
                      alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
                    ),
                  },
                }}
              >
                <Box sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
                >
                  <Icon>{option.icon}</Icon>
                </Box>

                {option.label}
              </MenuItem>
            );
          }
          return <Box key={option.label} />;
        })}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth variant="outlined" onClick={() => navigate('/logout')}>
            Log out
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
