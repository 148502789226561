import React, { FC } from 'react';

// material
import { Popover, Box, BoxProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ArrowStyle = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.default,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

const MenuPopover: FC<{
  children: React.ReactNode,
  sx: any,
  [key: string]: any
}> = ({ children, sx, ...other }) => (
  <Popover
    open={false}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    PaperProps={{
      sx: {
        mt: 1.5,
        ml: 0.5,
        overflow: 'inherit',
        boxShadow: (theme) => theme.shadows[20],
        border: (theme) => `solid 1px ${theme.palette.grey[500]}`,
        width: 200,
        backgroundColor: (theme) => theme.palette.background.default,
        ...sx,
      },
    }}
    {...other}
  >
    <ArrowStyle className="arrow" />

    {children}
  </Popover>
);

export default MenuPopover;
