import React, { useState } from 'react';

import { Box, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { IWorkspace } from '../../../../types/workspace';

export type UpdateUserFormType = {
  name: string;
};

type EditProfileType = {
  workspace: IWorkspace;
}

const OrganisationSettings: React.FC<EditProfileType> = ({ workspace }) => {
  const { register, handleSubmit } = useForm<UpdateUserFormType>();
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const onSubmit = async () => {
    setEditMode(false);
    setError(false);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1 }}
    >
      <TextField
        margin="normal"
        fullWidth
        required
        label="Name"
        type="text"
        defaultValue={workspace.name}
        error={error}
        InputProps={{ readOnly: !editMode }}
        helperText={error && 'There must be a name'}
        {...register('name', { required: true })}
      />
      <TextField
        margin="normal"
        fullWidth
        label="Identifier"
        type="text"
        defaultValue={workspace.dbName}
        disabled
      />
      <TextField
        margin="normal"
        fullWidth
        type="text"
        label="Created at"
        defaultValue={new Date(workspace.createdAt).toISOString().split('T')[0]}
        disabled
      />

    </Box>
  );
};

export default OrganisationSettings;
