import React, { FC, useContext, useEffect } from 'react';

import { Box, Container, Paper, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../context/auth-context';

type LogoutProps = {
  logout: () => Promise<boolean>;
}

const Logout: FC<LogoutProps> = ({ logout }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { setUser, user } = useContext(AuthContext);

  useEffect(() => {
    const doLogout = async () => {
      await logout();
      setUser(undefined);
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    };
    doLogout();
  }, [logout, navigate, setUser, user]);
  return (
    <Container component="main" maxWidth="sm">
      <Paper sx={{ px: 6, py: 2, backgroundColor: theme.palette.background.default }}>
        <Box>You have been successfully logged out!</Box>
        <Box>You will be redirected in 3 seconds.</Box>
      </Paper>
    </Container>
  );
};

export default Logout;
