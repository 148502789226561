import React, { FC, useContext } from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, List, Link, ListItemText, ListItemIcon, ListItemButton, ListItemButtonProps } from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';

// material

import AuthContext from '../../../context/auth-context';
import { NavMenuItem } from '../types/nav-menu-options';

// ----------------------------------------------------------------------

const ListItemStyle = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const validURL = (str: string): boolean => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
};

// ----------------------------------------------------------------------

type NavItemProps = {
  item: NavMenuItem,
  active: (path: string) => boolean
};

const NavItem: FC<NavItemProps> = ({ item, active }) => {
  const theme = useTheme();
  const isActiveRoot = active(item.path);
  const { label, path, icon, info } = item;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  };
  const content = (
    <ListItemStyle
      sx={{ ...(isActiveRoot && activeRootStyle) }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={(
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {validURL(item.path) && <OpenInNewIcon sx={{ pr: 1 }} />}
            {label}
          </Box>
                )}
      />
      {info && info}
    </ListItemStyle>
  );

  return validURL(item.path)
    ? (
      <Link
        href={path}
        target="_blank"
        style={{ textDecoration: 'none' }}
        sx={{ display: 'flex' }}
      >
        { content }
      </Link>
    )
    : (
      <RouterLink
        to={path}
        style={{ textDecoration: 'none' }}
      >
        { content }
      </RouterLink>
    );
};

type NavSectionProps = {
  menuItems: NavMenuItem[],
  [key: string]: any
};

const NavSection: FC<NavSectionProps> = ({ menuItems, ...other }) => {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const match = (path: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding>
        {menuItems.map((item) => {
          if (item.guard === user?.role || item.guard === '') {
            return (
              <NavItem key={item.label} item={item} active={match} />
            );
          }
          return <Box key={item.label} />;
        })}
      </List>
    </Box>
  );
};

export default NavSection;
