import React from 'react';

import { Box, Button, Step, StepLabel, Stepper, Typography } from '@mui/material';

import ConfluenceLinks from './confluence-links';
import CredentialsForm, { Credentials } from './credentials-form';
import { connectToConfluence } from '../../../../../services/confluence.service';
import { createOneToMultipleWithUrl } from '../../../../../services/connect.service';
import { ConfluenceResDto } from '../../../../../types/confluence.dto';
import { IImportWebsite } from '../../../../../types/website';

const FINISH_MESSAGE = (onBack: () => void, nr: number) => (
  <Box>
    <Typography variant="subtitle1" sx={{ p: 2, pt: 6, textAlign: 'center' }}>
      All steps completed -
      {' '}
      {nr}
      {' '}
      confluence pages got imported 🎉
    </Typography>
    <Button onClick={onBack} sx={{ mr: 1 }}>
      Back
    </Button>
  </Box>
);

const ConfluenceSettings: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [error, setError] = React.useState<string | null>(null);
  const [confluenceRes, setConfluenceRes] = React.useState<ConfluenceResDto | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [connectedWebsites, setConnectedWebsites] = React.useState<number>(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onFormSubmit = async (credentials: Credentials) => {
    // check credentials
    // if valid, go to next step: email is valid email, baseUrl is valid url
    if (credentials.email && credentials.password && credentials.baseUrl) {
      setError(null);
      setIsLoading(true);
      try {
        const res = await connectToConfluence(credentials.email, credentials.password, credentials.baseUrl);
        setIsLoading(false);
        if (res && res.pages && res.pages.length > 0) {
          setConfluenceRes(res);
          handleNext();
        } else {
          setError('No pages found');
        }
      } catch (err: any) {
        setIsLoading(false);
        setError('Check your credentials and try again.');
      }
    } else {
      // else, show error
      setIsLoading(false);
      setError('Please fill in all fields');
    }
  };

  const onLinksSubmit = async (links: { id: string, links: string[] }[]) => {
    // save links
    // go to next step
    if (!confluenceRes) return;
    setIsLoading(true);
    setError(null);

    const filteredPages: IImportWebsite[] = [];
    confluenceRes.pages.forEach((page) => {
      const pageLinks = links.find((link) => link.id === page.id);
      if (pageLinks) {
        const pageObj = {
          name: page.title,
          url: page.url,
          favicon: page.favicon,
          eId: page.id,
        };
        const linksObj = pageLinks.links.map((link) => ({
          name: '',
          url: link,
          favicon: '',
        }));
        filteredPages.push({ fromWebsite: pageObj, toWebsites: linksObj });
      }
    });

    try {
      // create connections between pages and links
      const connectRes = await createOneToMultipleWithUrl(filteredPages);
      setConnectedWebsites(connectRes);
      handleNext();
    } catch (err: any) {
      setError('Something went wrong by creating the links. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [{
    label: 'Add credentials',
    component: <CredentialsForm onSubmit={onFormSubmit} onCancel={handleReset} isLoading={isLoading} />,
  },
  {
    label: 'Check connections',
    component: <ConfluenceLinks
      confluenceRes={confluenceRes}
      onSubmit={onLinksSubmit}
      onBack={handleBack}
      isLoading={isLoading}
    />,
  }];

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        FINISH_MESSAGE(handleBack, connectedWebsites)
      ) : (
        <>
          <Box sx={{ p: 2, pt: 4 }}>
            {steps[activeStep].component}

          </Box>
          {error && <Typography variant="body1" sx={{ color: 'red', p: 2 }}>{error}</Typography>}
        </>
      )}
    </Box>
  );
};

export default ConfluenceSettings;
