import React, { useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Button, styled, Modal, ModalProps, Typography } from '@mui/material';

import { deleteRelation } from '../../../services/delete.service';
import { INode } from '../../../types/node';

const style = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  p: 2,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

type DeleteConnectionType = {
  node: INode;
  parentNode: INode;
  onDeleteConnection: (node: INode, parentNode: INode) => void;
  children: React.ReactNode;
}

const DeleteConnection: React.FC<DeleteConnectionType> = ({ node, parentNode, onDeleteConnection, children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<undefined | string>(undefined);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const success = await deleteRelation(node.id, parentNode.id);
    if (success) {
      setIsLoading(false);
      setError(undefined);
      onDeleteConnection(node, parentNode);
      handleClose();
    } else {
      setError('Something went wrong, please contact an administrator!');
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box onClick={handleOpen}>
        { children }
      </Box>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-title" variant="h4" component="h2">
            Delete connection between
            {' '}
            &quot;
            { parentNode.name }
            &quot;
            {' '}
            and
            {' '}
            &quot;
            { node.name }
            &quot;
            {' '}
            ?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Do you want to remove the connection between
            {' '}
            &quot;
            { parentNode.name }
            &quot;
            {' '}
            and
            {' '}
            &quot;
            { node.name }
            &quot;
            {' '}
            ?
          </Typography>

          {error
            && (
            <Alert severity="error">
              {error}
            </Alert>
            )}

          <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
            <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              color="error"
              onClick={onSubmit}
              loading={isLoading}
              loadingPosition="start"
              startIcon={<DeleteIcon />}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </ModalStyle>
    </Box>
  );
};

export default DeleteConnection;
