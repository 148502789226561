import React, { useContext, useState } from 'react';

import { Box, Card, CardContent, Grid, Typography, Stack } from '@mui/material';

import CurrentOverview from './current-overview';
import LicenceCard from './licence-card';
import OrganisationSettings from './organisation-settings';
import AuthContext from '../../../../context/auth-context';
import { getNrOfNodes } from '../../../../services/get.service';
import { getAllLicences } from '../../../../services/licence.service';
import { ILicence } from '../../../../types/licence';
import { IUser } from '../../../../types/user';

type LicenceManagerProps = {
  users: IUser[];
};

const LicenceManager: React.FC<LicenceManagerProps> = ({ users }) => {
  const { user } = useContext(AuthContext);
  const [nrNodes, setNrNodes] = useState(0);
  const [nrUser, setNrUser] = useState(0);
  const [licences, setLicences] = useState<ILicence[]>([]);
  const [workspaceLicence, setWorkspaceLicence] = useState<ILicence | undefined>(undefined);

  React.useEffect(() => {
    const loadNodes = async () => {
      const nr = await getNrOfNodes();
      setNrNodes(nr);
    };

    const loadLicences = async () => {
      const response = await getAllLicences();
      setLicences(response);
      if (user && user.workspace !== undefined) {
        const licence = response.find((l) => l.id === user.workspace?.licence);
        setWorkspaceLicence(licence);
      }
    };

    loadLicences();
    loadNodes();
    setNrUser(users.length);
  }, [setNrNodes, setNrUser, users, setLicences, user]);

  return user && user.workspace ? (
    <Box>
      <Stack sx={{ py: 4 }} spacing={2}>
        <Typography variant="h3" component="h3">
          Your Organisation
        </Typography>
        <Card>
          <CardContent>
            <OrganisationSettings workspace={user.workspace} />
          </CardContent>
        </Card>
        { workspaceLicence && <CurrentOverview licence={workspaceLicence} nrNodes={nrNodes} nrUsers={nrUser} /> }
      </Stack>
      <Box sx={{ py: 4 }}>
        <Typography variant="h3" component="h3">
          Plans
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            { licences.map((licence) => (
              <Grid item xs={12} sm={6} md={4} key={licence.id}>
                <LicenceCard licence={licence} selected={licence.id === user.workspace?.licence} />
              </Grid>
            ))}
          </Grid>
        </Box>

      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="h3" component="h3">
        Problems by identifing your workspace. Please contact the support.
      </Typography>
    </Box>
  );
};

export default LicenceManager;
