import React from 'react';

import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { deleteNode } from '../../../services/delete.service';
import { INode } from '../../../types/node';

type InfoNodeProps = {
  node: INode;
  // eslint-disable-next-line react/require-default-props
  editMode?: boolean;
  // eslint-disable-next-line react/require-default-props
  onDelete?: (node: INode) => void;
}

const InfoNode: React.FC<InfoNodeProps> = ({ node, editMode = false, onDelete }) => {
  const navigate = useNavigate();
  const getSeverity = (escalation: string) => {
    switch (escalation) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'critical':
        return 'error';

      default:
        return 'info';
    }
  };

  const onClick = () => {
    navigate(`/node?id=${node.id}`, { state: { node } });
  };

  const onClose = () => {
    deleteNode(node.id);
    if (onDelete) onDelete(node);
  };

  return (
    <Alert
      variant="filled"
      severity={getSeverity(node.escalation)}
      key={node.id}
      {
                ...!editMode && { onClick }
            }
      {
                ...editMode && { onClose }
            }
      sx={{ cursor: 'pointer' }}
    >
      {node.name}
    </Alert>
  );
};

export default InfoNode;
