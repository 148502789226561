import React from 'react';

// material
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  OutlinedInputProps,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)<OutlinedInputProps>(({ theme }: { theme: any}) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: '1px !important',
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

type UserListToolbarProps = {
  numSelected: number;
  filterName: string;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const UserListToolbar: React.FC<UserListToolbarProps> = ({ numSelected, filterName, onFilterName }) => (
  <RootStyle
    sx={{
      ...(numSelected > 0 && {
        color: 'white',
        bgcolor: 'primary.main',
      }),
    }}
  >
    {numSelected > 0 ? (
      <Typography component="div" variant="subtitle1">
        {numSelected}
        {' '}
        selected
      </Typography>
    ) : (
      <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search user..."
        startAdornment={(
          <InputAdornment position="start">
            <SearchIcon sx={{ color: 'text.disabled' }} />
          </InputAdornment>
                )}
      />
    )}

    {numSelected > 0 ? (
      <Tooltip title="Löschen">
        <IconButton color="inherit">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Liste filtern">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )}
  </RootStyle>
);

export default UserListToolbar;
