import React, { FC } from 'react';

// material ui
import LoginIcon from '@mui/icons-material/Login';
import MenuIcon from '@mui/icons-material/Menu';
import { alpha, Link, Stack } from '@mui/material';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

// components
import { MENU_OPTIONS } from './account-menu-options.mock';
import AccountPopover from './components/account-popover';
import { SearchBar } from './components/search-bar';
import { IUser } from '../../types/user';
import { MHidden } from '../utils/hide-responsive/index';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)<AppBarProps>(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  color: theme.palette.text.primary,
  [theme.breakpoints.up('lg')]: { width: `calc(100% - ${DRAWER_WIDTH + 1}px)` },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export type NavbarProps = {
  openSidebar: () => void,
  user: IUser | undefined
}

const Navbar: FC<NavbarProps> = ({ openSidebar, user }) => (
  <RootStyle sx={{ flexGrow: 1 }}>
    <ToolbarStyle>
      <MHidden width="lgUp">
        <IconButton
          onClick={openSidebar}
          sx={{ mr: 1, color: 'text.primary' }}
        >
          <MenuIcon />
        </IconButton>
      </MHidden>
      {!user && (
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Link
            id="login-link"
            href="/login"
            sx={{ display: 'flex' }}
          >
            <LoginIcon sx={{ mr: 1 }} />
            Login
          </Link>
        </Box>
      )}

      {user && (
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 1, pr: 1 }}>
            <SearchBar />
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
            data-testid="account-popover"
          >
            <AccountPopover menuOptions={MENU_OPTIONS} user={user} />
          </Stack>
        </Box>
      )}
    </ToolbarStyle>
  </RootStyle>
);

export default Navbar;
