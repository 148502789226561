import React, { useEffect, useState } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Stack,
  TableBody,
  Table,
  TableContainer,
  TableCell,
  Checkbox,
  MenuItem,
  TableRow,
  InputLabel,
  FormControlLabel,
  Paper,
  FormControl,
  FormGroup,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';

import InfoNode from './info-node';
import PersonNode from './person-node';
import { getLabels } from '../../../services/get.service';
import { updateNode } from '../../../services/update.service';
import { INode } from '../../../types/node';
import { IPerson } from '../../../types/person';
import { transformLabel } from '../../utils/helper';
import NodeList from '../../utils/node-list/node-list';

const EXCLUDE_LABELS = [
  'name',
  'url',
  'description',
  'label',
  'id',
  'favicon',
  'createdFrom',
  'escalation',
  'verified',
];
const ALIAS_LABEL_NAMES = new Map<string, string>([
  ['createdAt', 'Created at'],
]);

type UpdateNodeFormType = {
  name: string;
  label: string;
  description: string;
  verified: boolean;
};

const propConverter = (key: string, value: any): string => {
  switch (key) {
    case 'createdAt':
      return new Date(value).toISOString();
      break;

    default:
      return value.toString();
      break;
  }
};

type UpdateNodeProps = {
  node: INode;
  onUpdate: (node: INode) => void;
}
const UpdateNode: React.FC<UpdateNodeProps> = ({ node, onUpdate }) => {
  const { register, handleSubmit, reset } = useForm<UpdateNodeFormType>();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [relatedNodes, setRelatedNodes] = useState<INode[]>([]);
  const [infoNodes, setInfoNodes] = useState<INode[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  useEffect(() => {
    let isActive = true;
    const loadLabels = async () => {
      const result = await getLabels();
      if (result && isActive) setLabels(result.sort((a, b) => a.localeCompare(b)));
    };

    if (node.nodes) {
      setInfoNodes(node.nodes.filter((n) => n.label === 'info'));
      const restNodes = node.nodes.filter((n) => n.label !== 'info');
      setRelatedNodes(restNodes);
    }

    loadLabels();

    return () => { isActive = false; };
  }, [setLabels, setInfoNodes, setRelatedNodes, node.nodes]);

  const onSubmit = async (data: UpdateNodeFormType) => {
    setIsLoading(true);
    try {
      const dataCopy = { ...data };
      const updatedNodes = await updateNode({ ...node, ...dataCopy });
      if (updatedNodes && updatedNodes[0]) {
        setIsLoading(false);
        onUpdate(updatedNodes[0]);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const cancel = () => {
    reset(node);
    onUpdate({ ...node, nodes: [...relatedNodes, ...infoNodes] });
  };

  const deleteInfo = (infoNode: INode) => {
    setInfoNodes(infoNodes.filter((n) => n.id !== infoNode.id));
  };

  return (
    <Box sx={{ width: '100%' }}>
      {node && (
        <Stack>
          {node.label.toLowerCase() === 'person' ? <PersonNode person={node as IPerson} />
            : (
              <Card sx={{ margin: 'auto', width: isLargeScreen ? '600px' : '100%', p: 2, pt: 1 }}>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onSubmit)}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <Stack spacing={4}>

                    {/* Title Area in Node Card */}
                    <TextField
                      label="Titel"
                      defaultValue={node.name}
                      required
                      autoFocus
                      {...register('name', { required: true })}
                    />

                    {/* Label Area in Node Card */}
                    <FormControl fullWidth>
                      <InputLabel id="label-select" required>Choose a node type</InputLabel>
                      <Select
                        id="label-select"
                        sx={{ width: '100%' }}
                        defaultValue={node.label}
                        required
                        label="Choose a type"
                        {...register('label', { required: true })}
                      >
                        {labels.map((label) => (
                          <MenuItem value={label} key={label}>
                            {transformLabel(label)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Description Area in Node Card */}
                    <TextField
                      label="Description"
                      multiline
                      rows={4}
                      defaultValue={node.description}
                      {...register('description')}
                    />

                    {/* Verified Area in Node Card */}
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox defaultChecked={node.verified} />}
                        label="Verified"
                        {...register('verified')}
                      />
                    </FormGroup>

                    {/* Action area in Node Card */}
                    <Box sx={{ justifyContent: 'end', display: 'flex' }}>
                      <Button onClick={cancel} sx={{ mr: 2 }}>
                        Cancel
                      </Button>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>

                <TableContainer component={Paper}>
                  <Table aria-label="Information about the person">
                    <TableBody>
                      {Object.keys(node)
                        .filter((key) => (
                          !EXCLUDE_LABELS.includes(key)
                          && (typeof node[key] === 'string' || typeof node[key] === 'number')))
                        .map((key) => (
                          <TableRow
                            key={key}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography variant="body1" component="div" fontWeight="bold">
                                {ALIAS_LABEL_NAMES.get(key) || key}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="body1" component="div">
                                {propConverter(key, node[key])}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

              </Card>
            )}

          <Box>
            {
                            infoNodes.length > 0 && (
                            <Stack sx={{ mt: 2 }}>
                              {infoNodes.map((n) => <InfoNode key={n.id} node={n} onDelete={deleteInfo} editMode />)}
                            </Stack>
                            )
                        }

            {relatedNodes.length > 0
              && (
              <Box>
                <Divider sx={{ my: 3 }} />
                <Typography variant="h4" component="h4" sx={{ py: 1 }}>Related infromation</Typography>
                <NodeList parentNode={node} nodes={relatedNodes} wrapper fancyBox={false} editMode />
              </Box>
              )}
          </Box>

        </Stack>
      )}
    </Box>
  );
};

export default UpdateNode;
