import React, { useContext, useEffect } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, BoxProps, InputBase, InputBaseProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';

import SearchBarContext from '../../../context/search-bar.context';

const Search = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const SearchIconWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 100,
}));

const StyledInputBase = styled(InputBase)<InputBaseProps>(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    '&:focus': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[20],
    },
    [theme.breakpoints.up('sm')]: {
      width: 250,
      '&:focus': { width: '30ch' },
    },
  },
}));

export const SearchBar: React.FC = () => {
  const { setSearchTerm, searchTerm } = useContext(SearchBarContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const querySearchTerm = searchParams.get('search');
    setSearchTerm(querySearchTerm || '');
  }, [setSearchTerm, searchParams]);

  const navigateToSearch = () => {
    navigate(`/search?search=${searchTerm}`);
  };

  const onInput = (searchInput: string) => {
    setSearchTerm(searchInput);
    setSearchParams({ search: searchInput });
  };
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Search onClick={navigateToSearch}>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={(e) => onInput(e.target.value.toLowerCase())}
        />
      </Search>
    </Box>
  );
};
