import React, { useContext, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Chip,
  Card,
  Container,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Fab,
  TableBody,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

import InfoNode from './components/info-node';
import NodeOptions from './components/node-options';
import PersonNode from './components/person-node';
import UpdateNode from './components/update-node';
import AuthContext from '../../context/auth-context';
import { getNodeWithId } from '../../services/get.service';
import { INode } from '../../types/node';
import { IPerson } from '../../types/person';
import ConnectNodes from '../connect-nodes/connect-nodes';
import { transformLabel } from '../utils/helper';
import NodeListItemIcon from '../utils/list-item-icon/node-list-item-icon';
import LoadingSpinner from '../utils/loading-spinner/loading-spinner';
import NodeList from '../utils/node-list/node-list';
import PersonalFavoriteButton from '../utils/personal-favorite-button/personal-favorite-button';

const EXCLUDE_LABELS = [
  'name',
  'url',
  'description',
  'label',
  'id',
  'favicon',
  'createdFrom',
  'escalation',
  'verified',
];
const ALIAS_LABEL_NAMES = new Map<string, string>([
  ['createdAt', 'Erstellt am'],
]);

const propConverter = (key: string, value: any): string => {
  switch (key) {
    case 'createdAt':
      return new Date(value).toISOString();
      break;

    default:
      return value.toString();
      break;
  }
};

const NodeDetails: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [relatedNodes, setRelatedNodes] = useState<INode[]>([]);
  const inputNode = (state as { node: INode })?.node;
  const [node, setNode] = useState<INode | undefined>(inputNode || undefined);
  const [nodeUrl, setNodeUrl] = useState('');
  const [infoNodes, setInfoNodes] = useState<INode[]>([]);
  const [isLoadingNode, setIsLoadingNode] = useState(false);
  const [domainNode, setDomainNode] = useState<INode | undefined>(undefined);
  const [domainNodes, setDomainNodes] = useState<INode[]>([]);
  const [editMode, setEditMode] = useState(false);

  const addRelatedNodes = (nodes: INode[]) => {
    setRelatedNodes((prev) => {
      const nodeIds = relatedNodes.map((n) => n.id);
      const filteredNodes = nodes.filter((n) => !nodeIds.includes(n.id) && n.label !== 'info');
      return [...prev, ...filteredNodes];
    });
    setInfoNodes((prev) => {
      const nodeIds = infoNodes.map((n) => n.id);
      const filteredNodes = nodes.filter((n) => !nodeIds.includes(n.id) && n.label === 'info');
      return [...prev, ...filteredNodes];
    });
  };

  useEffect(() => {
    let active = true;

    const getNodes = async (id: number) => {
      setIsLoadingNode(true);
      const result = await getNodeWithId(id);
      if (result && active) {
        setNode(result);
        if (result.nodes) {
          setInfoNodes(result.nodes.filter((n) => n.label === 'info'));
          const restNodes = result.nodes.filter((n) => n.label !== 'info');
          setRelatedNodes(restNodes);
        }
        if (result.domainNode && result.domainNodes) {
          setDomainNode(result.domainNode);
          setDomainNodes(result.domainNodes);
        }
        setIsLoadingNode(false);
        setNodeUrl(result.url);
      }
    };

    setNode(inputNode);
    const queryId = +(searchParams.get('id') || inputNode.id);
    getNodes(queryId);

    return () => { active = false; };
  }, [setRelatedNodes,
    setInfoNodes,
    setNode,
    setNodeUrl,
    setIsLoadingNode,
    inputNode,
    searchParams,
    setDomainNode,
    setDomainNodes]);

  const navigateBack = () => navigate(-1);
  const navigateForward = () => navigate(1);
  const navigateHome = () => navigate('/');

  const addNewNodes = (nodes: INode[]) => {
    addRelatedNodes(nodes);
  };

  const onUpdate = async (updatedNode: INode) => {
    const result = await getNodeWithId(updatedNode.id);
    if (result) {
      setNode(result);
      if (result.nodes) {
        setInfoNodes(result.nodes.filter((n) => n.label === 'info'));
        const restNodes = result.nodes.filter((n) => n.label !== 'info');
        setRelatedNodes(restNodes);
      }
      if (result.domainNode && result.domainNodes) {
        setDomainNode(result.domainNode);
        setDomainNodes(result.domainNodes);
      }
      setIsLoadingNode(false);
      setNodeUrl(result.url);
    }
    setEditMode(() => false);
  };

  const toggleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const saveToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const connectTrigger = (
    <Fab
      color="primary"
      aria-label="add"
      sx={{ position: 'fixed', bottom: '3rem', right: '2rem' }}
    >
      <AddIcon />
    </Fab>
  );

  return (
    <Container>
      {node && (
        editMode
          ? <UpdateNode node={node} onUpdate={onUpdate} />
          : (
            <Stack>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: 1 }}>
                <IconButton aria-label="back" onClick={navigateBack}>
                  <ArrowBackIcon />
                </IconButton>

                <Button aria-label="home" onClick={navigateHome}>Home</Button>

                <IconButton aria-label="forward" onClick={navigateForward}>
                  <ArrowForwardIcon />
                </IconButton>
              </Box>

              <Box sx={{ alignSelf: 'center', width: isLargeScreen ? '600px' : '100%' }}>
                {node.label.toLowerCase() === 'person' ? <PersonNode person={node as IPerson} />
                  : (
                    <Card sx={{ p: 2, pt: 1 }}>

                      {/* Description Area in Node Card */}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1em', pb: '1em' }}>
                        <NodeListItemIcon node={node} />
                        <Typography variant="h4" component="h4">
                          {node.name}
                        </Typography>
                      </Box>

                      <Box sx={{ pb: 3, display: 'flex', alignItems: 'center' }}>
                        <Chip key={node.label} label={transformLabel(node.label)} sx={{ mr: 1 }} />
                      </Box>

                      {/* Description Area in Node Card */}
                      <Typography variant="body1" component="p" sx={{ pb: 1 }}>{node.description}</Typography>

                      {/* Property area in Node Card */}
                      <TableContainer component={Paper}>
                        <Table aria-label="Information about the person">
                          <TableBody>
                            {Object.keys(node)
                              .filter((key) => (
                                !EXCLUDE_LABELS.includes(key)
                            && (typeof node[key] === 'string' || typeof node[key] === 'number')))
                              .map((key) => (
                                <TableRow
                                  key={key}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    <Typography variant="body1" component="div" fontWeight="bold">
                                      {ALIAS_LABEL_NAMES.get(key) || key}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography variant="body1" component="div">
                                      {propConverter(key, node[key])}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      {/* Action area in Node Card */}
                      <Box sx={{ justifyContent: 'end', display: 'flex' }}>
                        {nodeUrl && (
                        <>
                          <Tooltip title={nodeUrl}>
                            <IconButton aria-label="copy" onClick={() => saveToClipboard(nodeUrl)}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={nodeUrl}>
                            <Button
                              variant="outlined"
                              onClick={() => window.open(nodeUrl)}
                              endIcon={<OpenIcon />}
                              sx={{ m: 1 }}
                            >
                              Open
                            </Button>
                          </Tooltip>
                        </>
                        )}
                        {user && <PersonalFavoriteButton user={user} nodeId={node.id} />}
                        <NodeOptions node={node} isAdmin={user?.role === 'admin'} toggleEditMode={toggleEditMode} />
                      </Box>

                    </Card>
                  )}
              </Box>

              {isLoadingNode
                ? <Box sx={{ height: '300px' }}><LoadingSpinner /></Box>
                : (
                  <Box>
                    {
                                            infoNodes.length > 0 && (
                                            <Stack sx={{ mt: 2 }}>
                                              {infoNodes.map((n) => <InfoNode key={n.id} node={n} />)}
                                            </Stack>
                                            )
                                        }

                    {relatedNodes.length > 0
                      ? (
                        <Box sx={{ pb: 20 }}>
                          <Divider sx={{ my: 3 }} />
                          <Typography variant="h4" component="h4" sx={{ py: 1 }}>Related information</Typography>
                          <NodeList nodes={relatedNodes} parentNode={node} wrapper fancyBox />
                        </Box>
                      )
                      : (
                        <Box sx={{ my: 6, mx: 3 }}>
                          <Typography variant="body1" component="p" sx={{ py: 1 }}>
                            No related objects found.
                          </Typography>
                        </Box>
                      )}
                    <ConnectNodes
                      trigger={(connectTrigger)}
                      parentNode={node}
                      onSuccess={(nodes) => addNewNodes(nodes.fromNodes)}
                    />

                    {domainNode && domainNodes.length > 0
                  && (
                  <Box sx={{ mt: 12 }}>
                    <Typography variant="h4" component="h4" sx={{ py: 1 }}>
                      General infromation
                    </Typography>
                    <NodeList parentNode={node} nodes={[domainNode, ...domainNodes]} fancyBox wrapper />
                  </Box>
                  )}

                  </Box>
                )}
            </Stack>
          )
      )}
    </Container>
  );
};

export default NodeDetails;
