import axios from 'axios';

import { getConfig } from './utils';
import { ILicence } from '../types/licence';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const requestLicence = async (licence: string) => {
  const config = await getConfig();
  const result = await axios.post(`${baseUrl}/workspace/licence`, { licence }, config);
  return result;
};

export const getAllLicences = async (): Promise<ILicence[]> => {
  const config = await getConfig();
  const result = (await axios.get(`${baseUrl}/licence/all`, config)).data as ILicence[];
  return result;
};
