import React, { useEffect, useState } from 'react';

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@mui/material';

import { getFaviconFromUrl, transformLabel, validURL } from '../helper';

const LABELS = ['info', 'website', 'frage'];
const ESCALATION_LEVELS = ['info', 'warning', 'critical'];
const transformEscalationLevel = (level: string) => {
  switch (level) {
    case 'info':
      return 'Info';
    case 'warning':
      return 'Warning';
    case 'critical':
      return 'Critical';

    default:
      return '';
  }
};

export type NodeDetails = {
  name: string,
  label: string,
  description: string,
  url?: string | undefined,
  favicon?: string | undefined,
  escalation?: string | undefined,
}

type CreateNodeFormType = {
  onInput: (nodeDetails: NodeDetails) => void;
  initValue: NodeDetails;
}

export const CreateNodeForm: React.FC<CreateNodeFormType> = ({ onInput, initValue }) => {
  const [labels] = useState<string[]>(LABELS);
  const [selectedLabel, setSelectedLabel] = useState<string>(initValue.label);
  const [url, setUrl] = useState(initValue.url || '');
  const [description, setDescription] = useState(initValue.description);
  const [name, setName] = useState(validURL(initValue.url || '') ? '' : initValue.name);
  const [favicon, setFavicon] = useState<string | undefined>(undefined);
  const [escalation, setEscalation] = useState('');

  const updateDetails = (nodeDetails: Partial<NodeDetails>) => {
    const oldNode = {
      ...initValue,
      label: selectedLabel,
      name,
      description,
      url,
      favicon,
      escalation,
    };
    const newNode = { ...oldNode, ...nodeDetails } as NodeDetails;
    if (newNode.url === '') delete newNode.url;
    if (newNode.favicon === '') delete newNode.favicon;
    if (newNode.escalation === '') delete newNode.escalation;
    onInput(newNode);
  };

  useEffect(() => {
    let active = true;

    const loadWebsiteInfo = async () => {
      // const title = await getInfoFromUrl(url);
      const faviconUrl = getFaviconFromUrl(url);
      // setName(title);
      setFavicon(faviconUrl);
    };

    if (active && selectedLabel === 'website') {
      loadWebsiteInfo();
    }

    // Cleanup function will run bevore the next render
    return () => {
      active = false;
    };
  }, [url, setName, selectedLabel]);

  return (

    <>
      <TextField
        margin="normal"
        fullWidth
        label="Titel"
        type="text"
        required
        value={name}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setName(event.target.value);
          updateDetails({ name: event.target.value });
        }}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="label-select">Select a type</InputLabel>
        <Select
          id="label-select"
          sx={{ width: '100%' }}
          value={selectedLabel}
          label="Select a category"
          onChange={(event) => {
            setSelectedLabel(event.target.value as string);
            updateDetails({ label: event.target.value as string });
          }}
        >
          {labels.map((label) => (
            <MenuItem value={label} key={label}>
              {transformLabel(label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedLabel !== 'info' && (
        <>
          <TextField
            multiline
            minRows={2}
            maxRows={6}
            margin="normal"
            fullWidth
            label="Description (optional)"
            type="text"
            required
            value={description}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(event.target.value);
              updateDetails({ description: event.target.value });
            }}
          />
          <TextField
            margin="normal"
            fullWidth
            label={selectedLabel === 'website' ? 'URL' : 'URL (optional)'}
            type="text"
            required={selectedLabel === 'website'}
            helperText="If this object has a URL, insert it here"
            value={url}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUrl(event.target.value);
              updateDetails({ url: event.target.value });
            }}
          />
        </>
      )}
      {selectedLabel === 'info' && (
        <FormControl fullWidth margin="normal">
          <InputLabel id="label-select-label">Importance*</InputLabel>
          <Select
            labelId="label-select-label"
            label="Importance"
            value={escalation}
            onChange={(event) => {
              setEscalation(event.target.value as string);
              updateDetails({ escalation: event.target.value as string });
            }}
          >
            {ESCALATION_LEVELS.map((l) => (
              <MenuItem key={l} value={l}>{transformEscalationLevel(l)}</MenuItem>
            ))}
          </Select>
          <FormHelperText>
            The importance of this information
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};
