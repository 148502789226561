import axios from 'axios';

import { getConfig } from './utils';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const deleteWorkspace = async (): Promise<boolean> => {
  await axios.delete(`${baseUrl}/workspace/`, await getConfig());
  return true;
};
