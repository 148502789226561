import React, { FC, useContext, useEffect, useState } from 'react';

import { Box, CircularProgress } from '@mui/material';
import { Routes, Route } from 'react-router-dom';

import './App.scss';
import Login from './components/login/login';
import Logout from './components/logout/logout';
import { SearchResults } from './components/search-results/search-results';
import Signup from './components/signup/signup';
import AuthContext from './context/auth-context';
import Layout from './layouts';
import DashboardPage from './layouts/dashboard-page/dashboard-page';
import MapPage from './layouts/map-page/map-page';
import NodeOverview from './layouts/node-overview/node-overview';
import ProfilePage from './layouts/profile-page/profile-page';
import SettingsPage from './layouts/settings-page/settings-page';
import { getUser, login, logout } from './services/auth.service';
import { areRequiredTokensInStore } from './services/aws.service';

const App: FC = () => {
  const { user, setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const reauthenticate = async () => {
      setIsLoading(true);
      if (!user) {
        const requiredTokensExist = areRequiredTokensInStore();
        if (requiredTokensExist) {
          const authUser = await getUser();
          if (authUser) {
            setUser(authUser);
          }
        } else {
          await logout();
        }
      }
      setIsLoading(false);
    };

    reauthenticate();
  }, [setUser, setIsLoading, user]);

  const authRoutes = (
    <Route>
      <Route path="/" element={<DashboardPage />} />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="box" element={<DashboardPage />} />
      <Route path="search" element={<SearchResults />} />
      <Route path="node" element={<NodeOverview />} />
      <Route path="map" element={<MapPage />} />
      <Route path="profile" element={<ProfilePage />} />
      <Route path="settings/*" element={<SettingsPage />} />
      <Route path="*" element={<DashboardPage />} />
    </Route>
  );

  const allRoutes = (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* private routes */}
        {user && authRoutes}

        {/* public routes */}
        <Route path="" element={<Login login={login} />} />
        <Route path="signup" element={<Signup />} />
        <Route path="logout" element={<Logout logout={logout} />} />
        <Route path="*" element={<Login login={login} />} />
      </Route>
    </Routes>
  );

  const displayLoading = (
    <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
      <CircularProgress sx={{ margin: 'auto' }} />
    </Box>
  );

  return isLoading ? displayLoading : allRoutes;
};

export default App;
