import axios from 'axios';

import { getConfig } from './utils';
import { INode } from '../types/node';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const getEntitiesForSearch = async (term: string)
  : Promise<INode[] | undefined> => {
  try {
    const config = await getConfig();
    const result = (
      await axios.get(`${baseUrl}/get/search/`, { ...config, params: { term } })
    ).data as INode[];
    return result;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const getNodeWithId = async (id: number)
  : Promise<INode | undefined> => {
  try {
    const config = await getConfig();
    const result = (
      await axios.get(`${baseUrl}/get/id`, { ...config, params: { id } })
    ).data as INode;
    return result;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const getAllNodesWithLabel = async (label: string): Promise<INode[]> => {
  try {
    const config = await getConfig();
    const nodes = (
      await axios.get(`${baseUrl}/get/label`, { ...config, params: { label } })
    ).data as INode[];
    return nodes;
  } catch (error) {
    // No Cookie set
    return [];
  }
};

export const getFirstNodes = async (nr?: number): Promise<INode[]> => {
  try {
    const config = await getConfig();
    const nodes = (
      await axios.get(`${baseUrl}/get/random`, { ...config, params: { nr } })
    ).data as INode[];
    return nodes;
  } catch (error) {
    // No Cookie set
    return [];
  }
};

export const getLabels = async (): Promise<string[]> => {
  try {
    const config = await getConfig();
    const labels = (
      await axios.get(`${baseUrl}/get/labels`, { ...config })
    ).data as string[];
    return labels;
  } catch (error) {
    // No Cookie set
    return [];
  }
};

export const getPersFavorites = async (): Promise<INode[]> => {
  try {
    const config = await getConfig();
    const nodes = (
      await axios.get(`${baseUrl}/get/favorites/personal`, { ...config })
    ).data as INode[];
    return nodes;
  } catch (error) {
    // No Cookie set
    return [];
  }
};

export const getNrOfNodes = async (): Promise<number> => {
  const config = await getConfig();
  const nodes = (
    await axios.get(`${baseUrl}/get/nrnodes`, { ...config })
  ).data as number;
  return nodes;
};

export const getOrgFavorites = async (): Promise<INode[]> => {
  try {
    const config = await getConfig();
    const nodes = (
      await axios.get(`${baseUrl}/get/favorites/organisation`, { ...config })
    ).data as INode[];
    return nodes;
  } catch (error) {
    // No Cookie set
    return [];
  }
};

export const getLastNodes = async (nr: number): Promise<INode[]> => {
  try {
    const config = await getConfig();
    const nodes = (
      await axios.get(`${baseUrl}/get/last/?nr=${nr}`, { ...config })
    ).data as INode[];
    return nodes;
  } catch (error) {
    // No Cookie set
    return [];
  }
};
