import React from 'react';

import { Typography, Stack, Box } from '@mui/material';

import BoxListItem from './box-list-item';
import { IBox } from '../../../types/box';

type BoxListProps = {
  boxes: IBox[]
}

const BoxList: React.FC<BoxListProps> = ({ boxes }) => (
  <Stack gap={2}>
    {boxes.length > 0
      ? boxes.map((box) => <Box key={box.id} sx={{ p: 0, width: '100%' }}><BoxListItem box={box} /></Box>)
      : (
        <Typography variant="body1" sx={{ p: '1em', textAlign: 'center' }}>
          No objects found 😕
        </Typography>
      )}
  </Stack>
);

export default BoxList;
