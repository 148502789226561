import React, { useContext, useState } from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from '@mui/material';

import FavContext from '../../../context/fav-context';
import { addPersFavorite } from '../../../services/connect.service';
import { deleteRelation } from '../../../services/delete.service';
import { IUser } from '../../../types/user';

type PersonalFavoriteButtonProps = {
  nodeId: number,
  user: IUser,
}

const PersonalFavoriteButton: React.FC<PersonalFavoriteButtonProps> = ({ nodeId, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isPersFav, togglePersFav } = useContext(FavContext);

  const toggleFavorite = async () => {
    setIsLoading(true);
    if (!user.nodeId) {
      setIsLoading(false);
      // eslint-disable-next-line no-alert
      alert('Your user account does not have an ID, please contact an administrator!');
      return;
    }
    if (isPersFav(nodeId)) {
      // remove from Favorites
      const result = await deleteRelation(user.nodeId, nodeId);
      if (result) {
        togglePersFav(nodeId);
      }
    } else {
      // add to Favorites
      const favNode = await addPersFavorite(nodeId);
      if (favNode) {
        togglePersFav(nodeId);
      }
    }
    setIsLoading(false);
  };

  return (
    <Box sx={{ m: 1, position: 'relative', display: 'inline' }}>
      <IconButton onClick={toggleFavorite} disabled={isLoading}>
        {isPersFav(nodeId)
          ? (<Tooltip title="Ist einer deiner Favoriten"><BookmarkIcon color="primary" /></Tooltip>)
          : (<Tooltip title="Zu Favoriten hinzufügen"><BookmarkAddIcon /></Tooltip>)}
      </IconButton>
      {isLoading && (
        <CircularProgress
          size={32}
          sx={{
            position: 'absolute',
            bottom: 5,
            right: 4,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default PersonalFavoriteButton;
