import React from 'react';

import ApartmentIcon from '@mui/icons-material/Apartment';
import ComputerIcon from '@mui/icons-material/Computer';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Avatar,
  ListItemAvatar,
  useTheme,
  SvgIcon,
  Badge,
  Tooltip,
} from '@mui/material';

import { ReactComponent as BoxSvg } from '../../../assets/icons/box.svg';
import { INode } from '../../../types/node';

const getInfoIcon = (escalation: string) => {
  switch (escalation) {
    case 'info':
      return <InfoIcon />;
    case 'warning':
      return <WarningAmberIcon />;
    case 'critical':
      return <ErrorOutlineIcon />;
    default:
      return <InfoIcon />;
  }
};

const getIcon = (label: string) => {
  switch (label) {
    case 'organisation':
      return <ApartmentIcon />;
    case 'document':
      return <DescriptionIcon />;
    case 'software':
      return <ComputerIcon />;
    case 'abteilung':
      return <GroupsIcon />;
    case 'box':
      return <SvgIcon><BoxSvg /></SvgIcon>;
    default:
      return <LinkIcon />;
  }
};

const getInfoColor = (escalation: string) => {
  switch (escalation) {
    case 'info':
      return 'info';
    case 'warning':
      return 'warning';
    case 'critical':
      return 'error';
    default:
      return 'info';
  }
};

type NodeListItemIconProps = {
  node: INode;
}

const NodeListItemIcon: React.FC<NodeListItemIconProps> = ({ node }) => {
  const theme = useTheme();
  const faviconAvatar = (n: INode) => (
    <Avatar src={n.favicon}>
      {`${n.name[0]}${n.name[1]}`}
    </Avatar>
  );
  const profileAvatar = (n: INode) => (
    <Avatar src={n.imageUrl} sx={{ bgcolor: theme.palette.secondary.dark }}>
      {`${n.name.split(' ')[0][0]}${n.name.split(' ')[1][0]}`}
    </Avatar>
  );

  const infoAvatar = (escalation: string) => (
    <Avatar sx={{ bgcolor: theme.palette[getInfoColor(escalation)].main }}>
      {getInfoIcon(escalation)}
    </Avatar>
  );

  const otherAvatar = (label: string) => (
    <Avatar sx={{ bgcolor: theme.palette.secondary.dark }}>
      {getIcon(label)}
    </Avatar>
  );

  const getAvatar = () => {
    if (node.favicon) return faviconAvatar(node);
    if (node.label === 'person') return profileAvatar(node);
    if (node.label === 'info') return infoAvatar(node.escalation);
    return otherAvatar(node.label);
  };

  return (
    <ListItemAvatar>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
                    (node.verified
            && (
            <Tooltip title="This object has been verified by an administrator">
              <VerifiedIcon
                color="info"
                sx={{ background: theme.palette.background.paper, borderRadius: '50%' }}
              />
            </Tooltip>
            )
                    )
                }
      >
        {getAvatar()}
      </Badge>
    </ListItemAvatar>
  );
};

export default NodeListItemIcon;
