import React from 'react';

import { Box, Card, CardContent, LinearProgress, Typography } from '@mui/material';

import { ILicence } from '../../../../types/licence';

type CurrentOverviewProps = {
  licence: ILicence;
  nrNodes: number;
  nrUsers: number;
};

const CurrentOverview: React.FC<CurrentOverviewProps> = ({ licence, nrNodes, nrUsers }) => {
  const displayNrNodes = nrNodes > licence.nodeLimit ? licence.nodeLimit : nrNodes;
  return (
    <Card>
      <CardContent>
        <Typography variant="body1" component="div">
          Here you can see the limits of your current licence.
          If you want to upgrade your licence, please contact us.
        </Typography>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ width: '50%', p: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(displayNrNodes / licence.nodeLimit) * 100}
              sx={{ height: '10px', borderRadius: 5 }}
            />
            <Typography variant="body1" component="div">
              {nrNodes}
              {' '}
              of
              {' '}
              {licence.nodeLimit === -1 ? 'unlimited' : licence.nodeLimit}
              {' '}
              Nodes
            </Typography>
          </Box>
          <Box sx={{ width: '50%', p: 2 }}>
            <LinearProgress
              variant="determinate"
              value={(nrUsers / licence.userLimit) * 100}
              sx={{ height: '10px', borderRadius: 5 }}
            />
            <Typography variant="body1" component="div">
              {nrUsers}
              {' '}
              out of
              {' '}
              {licence.userLimit === -1 ? 'unlimited' : licence.userLimit}
              {' '}
              Users
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
export default CurrentOverview;
