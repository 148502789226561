import React, { useRef, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import DeleteUser from './delete-user';
import EditUser from './edit-user';
import { IUser } from '../../../../types/user';

// ----------------------------------------------------------------------

type UserListMoreMenuProps = {
  user: IUser;
  editUser: (user: IUser) => void;
}

const UserListMoreMenu: React.FC<UserListMoreMenuProps> = ({ user, editUser }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{ sx: { width: 200, maxWidth: '100%' } }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <DeleteUser user={user}>
          <MenuItem sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <DeleteIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </DeleteUser>

        <EditUser user={user} editUser={editUser}>
          <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <EditIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </EditUser>
      </Menu>
    </>
  );
};

export default UserListMoreMenu;
