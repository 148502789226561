import React, { useState } from 'react';

import { VisibilityOff, Visibility } from '@mui/icons-material';
import PersonEditIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import {
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { updateUser } from '../../../../services/user.service';
import { IUser } from '../../../../types/user';
import CustomModal, { CustomModalRef } from '../../../utils/modal/modal';

type EditUserFormType = {
  firstname: string;
  lastname: string;
  email: string;
  role: 'admin' | 'standard';
  password?: string | undefined;
};

type EditUserProps = {
  user: IUser;
  editUser: (user: IUser) => void;
  children: React.ReactNode;
}

const EditUser: React.FC<EditUserProps> = ({ user, editUser, children }) => {
  const { register, handleSubmit, reset } = useForm<EditUserFormType>();
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const modalRef = React.useRef<CustomModalRef>(null);

  const onClose = () => {
    reset();
  };

  const closeModal = () => {
    modalRef.current?.handleClose();
  };

  const onSubmit = async (data: EditUserFormType) => {
    setIsLoading(true);
    try {
      const dataCopy = { ...data };
      if (data.password === '') delete dataCopy.password;
      const updatedUser = { ...user, ...dataCopy };
      const editedUser = await updateUser(user.id, updatedUser);
      if (editedUser && editedUser.id) {
        setTimeout(() => {
          setIsLoading(false);
          closeModal();
          editUser(editedUser);
        }, 2000);
      } else {
        setIsLoading(false);
        setError(true);
      }
    } catch (e) {
      setIsLoading(false);
      setError(true);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <CustomModal
      ref={modalRef}
      triggerNode={children}
      onClose={onClose}
    >
      <Typography id="modal-modal-title" variant="h4" component="h2">
        Edit User
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin="normal"
          fullWidth
          required
          label="Firstname"
          type="text"
          autoFocus
          defaultValue={user.firstname}
          error={error}
          helperText={error && 'Please enter a firstname'}
          {...register('firstname', { required: true })}
        />
        <TextField
          margin="normal"
          fullWidth
          required
          label="Lastname"
          type="text"
          autoFocus
          defaultValue={user.lastname}
          error={error}
          helperText={error && 'Please enter a lastname'}
          {...register('lastname', { required: true })}
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="role">Role</InputLabel>
          <Select
            labelId="role"
            id="role"
            label="Role"
            defaultValue={user.role}
            {...register('role', { required: true })}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="standard">Standard</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="normal"
          fullWidth
          required
          label="Email Address"
          type="email"
          autoFocus
          defaultValue={user.email}
          error={error}
          helperText={error && 'Please enter an email address'}
          {...register('email', {
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: 'Entered value does not match email format',
            },
          })}
        />

        <FormControl sx={{ my: 2, width: '100%' }} variant="outlined">
          <InputLabel htmlFor="password">Password*</InputLabel>
          <OutlinedInput
            id="password"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
                        )}
            label="Password*"
            {...register('password', { required: false })}
          />
        </FormControl>

        {error
              && (
              <Box sx={{ my: 2, color: 'error.main' }}>
                Check your email and password and try again.
              </Box>
              )}

        <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
          <Button onClick={closeModal} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isLoading}
            loadingPosition="start"
            startIcon={<PersonEditIcon />}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default EditUser;
