import React, { SyntheticEvent, useEffect } from 'react';

import {
  Autocomplete,
  Box,
  ListItem,
  TextField,
  ListItemText,
  debounce,
} from '@mui/material';

import { CreateNodeForm, NodeDetails } from './create-node-form';
import { getEntitiesForSearch } from '../../../services/get.service';
import { INode } from '../../../types/node';
import { getFaviconFromUrl, getInfoFromUrl, transformLabel } from '../helper';
import NodeListItemIcon from '../list-item-icon/node-list-item-icon';

const identifyLabel = (input: string) => {
  // check if input is an url and set label to website
  if (input.startsWith('http')) {
    return 'website';
    // check if input is a question and set label to question
  } if (input.includes('?')) {
    return 'frage';
    // check if input is two words long and set label to person
  }
  return 'info';
};

type NodeSelectionType = {
  onSelect: (node: INode) => void;
}

export const NodeSelection: React.FC<NodeSelectionType> = ({ onSelect }) => {
  const [value, setValue] = React.useState<INode | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly INode[]>([]);

  const createNewNodeTrigger = !!(value && value.id === -1);

  const fetch = React.useMemo(
    () => debounce(
      async (
        request: string,
        callback: (results?: readonly INode[]) => void,
      ) => {
        const results = await getEntitiesForSearch(request);
        callback(results);
      },
      300,
    ),
    [],
  );

  useEffect(() => {
    let active = true;

    if (value) {
      onSelect(value);
    }
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue, async (results?: readonly INode[]) => {
      const createNewNode = async (name: string, label: string): Promise<INode | undefined> => {
        let initNodeValues: INode = {
          id: -1,
          name,
          description: '',
          verified: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          label,
        };
        if (label === 'website') {
          const title = await getInfoFromUrl(name);
          const faviconUrl = getFaviconFromUrl(name);
          initNodeValues = {
            ...initNodeValues,
            name: title,
            url: name,
            verified: false,
            favicon: faviconUrl,
          };
        }
        return initNodeValues;
      };

      if (active) {
        let newOptions: readonly INode[] = [];
        let newNode: INode | undefined;

        if (inputValue !== '' && inputValue.length > 6) {
          const label = identifyLabel(inputValue);
          newNode = await createNewNode(inputValue, label);
        }

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        // Check if the new node is already in the list of options by its name or url
        if (newNode && !newOptions.some((option) => option.name === newNode?.name || option.url === newNode?.url)) {
          newOptions = [newNode, ...newOptions];
        }

        setOptions(newOptions);
      }
    });

    // Cleanup function will run bevore the next render
    return () => {
      active = false;
    };
  }, [setOptions, fetch, inputValue, value, createNewNodeTrigger, onSelect]);

  const subTitle = (node: INode) => (node.label === 'website'
    ? node.url
    : transformLabel(node.label));

  const nodeItem = (props: React.HtmlHTMLAttributes<HTMLLIElement>, node: INode) => (
    <ListItem {...props} key={node.id}>
      <NodeListItemIcon node={node} />
      <ListItemText
        primary={node.name}
        secondary={subTitle(node)}
      />
    </ListItem>
  );

  const updateValue = (nodeDetails: NodeDetails) => {
    if (!value) return;
    const newNode = {
      ...value,
      ...nodeDetails,
    };
    setValue(newNode);
  };

  return (
    <Box>
      <Box sx={{ mb: '2em' }}>
        {(!value || (value && value.id !== -1)) && (
        <Autocomplete
          id="node-search"
          sx={{ width: '100%' }}
          value={value}
          autoComplete
          filterSelectedOptions
          freeSolo
          autoHighlight
          selectOnFocus
          autoSelect
          disabled={createNewNodeTrigger && value.label === 'website'}
          onChange={(event: SyntheticEvent<Element, Event>, newValue: string | INode | null) => {
            // If the new value is a string, set a timeout to avoid instant validation of the dialog's form
            if (typeof newValue !== 'string') {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          getOptionLabel={(option) => {
            // If the option is a string, return the string as the label
            if (typeof option === 'string') {
              return option;
            }
            // If the option has an `inputValue` property, return the `inputValue` property as the label
            if (option.inputValue) {
              return option.inputValue;
            }
            // Otherwise, return the `name` property as the label
            return option.name;
          }}
          filterOptions={(x) => x}
          options={options}
          renderOption={(props, option) => {
            if (typeof option !== 'string') {
              return nodeItem(props, option);
            }
            return <Box>{option}</Box>;
          }}
          renderInput={(params) => (
            <TextField {...params} label="Add URL, name, question or info..." />
          )}
        />
        )}
      </Box>
      {/* only show if user wants to create a new node */}
      { createNewNodeTrigger && value
        && (
        <CreateNodeForm
          initValue={value}
          onInput={updateValue}
        />
        )}
    </Box>
  );
};
