import React, { createContext, FC, useState } from 'react';

import { IUser } from '../types/user';

// Define Context Type
interface IAuthContext {
  user: undefined | IUser,
  setUser: (user: undefined | IUser) => void
}

// Provider
const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IUser | undefined>(undefined);

  return (
  // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Export to app
export default AuthContext;
