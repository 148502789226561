/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, IconButton, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { ConfluenceResDto } from '../../../../../types/confluence.dto';

type Props = {
  confluenceRes: ConfluenceResDto | null;
  onSubmit: (links: { id: string, links: string[] }[]) => void;
  onBack: () => void;
  isLoading: boolean;
};

const ConfluenceLinks: React.FC<Props> = ({ confluenceRes, onSubmit, onBack, isLoading }) => {
  const resultMap = new Map<string, string[]>();
  if (confluenceRes !== null) {
    confluenceRes.pages.forEach((page) => {
      const links = page.links.map((linkObj) => linkObj.link);
      resultMap.set(page.id, links);
    });
  }
  const [pagesToSave, setPagesToSave] = React.useState <Map<string, string[]>>(resultMap);

  const handleToggle = (parentId: string, link: string) => () => {
    const newPagesToSave = new Map(pagesToSave);
    const links = newPagesToSave.get(parentId);
    if (links) {
      const index = links.indexOf(link);
      if (index === -1) {
        links.push(link);
      } else {
        links.splice(index, 1);
      }
      newPagesToSave.set(parentId, links);
      setPagesToSave(newPagesToSave);
    }
  };

  const handlePageToggle = (parentId: string) => () => {
    const newPagesToSave = new Map(pagesToSave);
    const links = newPagesToSave.get(parentId);
    if (links) {
      if (links.length === 0) {
        newPagesToSave.set(
          parentId,
          confluenceRes!.pages.find((page) => page.id === parentId)!.links.map((linkObj) => linkObj.link),
        );
      } else {
        newPagesToSave.set(parentId, []);
      }
      setPagesToSave(newPagesToSave);
    }
  };

  const handleSubmit = () => {
    const linksToSave = Array.from(pagesToSave.entries()).map(([id, links]) => ({ id, links }));
    onSubmit(linksToSave);
  };

  const handleOpenLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Select the links you want to save.
      </Typography>
      {confluenceRes && confluenceRes.pages && confluenceRes.pages.length > 0 ? (
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 600,
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {confluenceRes.pages.map((page) => (
            <li key={`section-${page.id}`}>
              <ul>
                <ListSubheader sx={{ p: 1, backgroundColor: '#F4F6F8', borderRadius: '12px' }}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={
                                                pagesToSave.get(page.id)
                        && pagesToSave.get(page.id)!.length === page.links.length
                                            }
                      tabIndex={-1}
                      disabled={isLoading}
                      disableRipple
                      onClick={handlePageToggle(page.id)}
                      inputProps={{ 'aria-labelledby': page.url }}
                    />
                  </ListItemIcon>
                  {page.title}
                  <Typography variant="body2" color="text.secondary">
                    (
                    {page.url}
                    )
                  </Typography>

                </ListSubheader>
                {page.links.map((link) => (
                  <ListItem
                    key={`item-${page.id}-${link.link}`}
                    secondaryAction={(
                      <IconButton aria-label="open" onClick={() => handleOpenLink(link.link)}>
                        <OpenInNewIcon />
                      </IconButton>
                                        )}
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(page.id, link.link)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                                                        pagesToSave.get(page.id)
                            && pagesToSave.get(page.id)!.indexOf(link.link) !== -1
                                                    }
                          tabIndex={-1}
                          disableRipple
                          disabled={isLoading}
                          inputProps={{ 'aria-labelledby': link.link }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={link.link} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <Box sx={{ pb: 4 }} />
              </ul>
            </li>
          ))}
        </List>
      ) : (
        <Box>Something went wrong, reset and try again.</Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }} />
        <Button onClick={onBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          loading={isLoading}
        >
          Save
        </LoadingButton>
      </Box>

    </Box>
  );
};

export default ConfluenceLinks;
