import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import App from './App';
import { AuthProvider } from './context/auth-context';
import { BoxProvider } from './context/box-context';
import { FavProvider } from './context/fav-context';
import { SearchBarProvider } from './context/search-bar.context';
import GlobalStyles from './theme/globalStyles';
import ThemeConfig from './theme/index';

ReactDOM.render(
  <ThemeConfig>
    <React.StrictMode>
      <BrowserRouter>
        <AuthProvider>
          <SearchBarProvider>
            <BoxProvider>
              <FavProvider>
                <GlobalStyles />
                <App />
              </FavProvider>
            </BoxProvider>
          </SearchBarProvider>
        </AuthProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ThemeConfig>,
  document.getElementById('root'),
);
