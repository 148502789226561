import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Button, Card, CardContent, Chip, Typography, useTheme } from '@mui/material';

import LicenceRequest from './licence-request';
import { ILicence } from '../../../../types/licence';

type LicenceCardProps = {
  licence: ILicence;
  selected: boolean;
};

const LicenceCard: React.FC<LicenceCardProps> = ({ licence, selected }) => {
  const theme = useTheme();
  return (
    <Card sx={{ height: '100%', border: selected ? `2px solid ${theme.palette.primary.main}` : 'none' }}>
      <CardContent sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      >
        <Box>
          <Typography variant="h4" component="h4">
            { licence.name }
          </Typography>
          <Typography variant="h6" component="h6">
            { licence.price }
          </Typography>

          <Box>
            {
                            licence.features.map((feature) => (
                              <Box key={feature} sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                                <CheckCircleOutlineIcon sx={{ mr: 1 }} />
                                <Typography variant="body1" component="div">
                                  { feature }
                                </Typography>
                              </Box>
                            ))
                        }
          </Box>

        </Box>
        <Box sx={{ textAlign: 'center' }}>
          { !selected
            && (
            <LicenceRequest licence={licence}>
              <Button sx={{ mt: 2, alignSelf: 'center' }} variant="contained" type="button">Request</Button>
            </LicenceRequest>
            )}
          { selected && (
            <Chip
              sx={{ mt: 2, p: 2, fontWeight: 'bolder' }}
              variant="outlined"
              color="primary"
              label="Your plan"
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default LicenceCard;
