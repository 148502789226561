import { merge } from 'lodash';

import Autocomplete from './Autocomplete.js';
import Backdrop from './Backdrop.js';
import Button from './Button.js';
import Card from './Card.js';
import IconButton from './IconButton.js';
import Input from './Input.js';
import Lists from './Lists.js';
import Paper from './Paper.js';
import Tooltip from './Tooltip.js';
import Typography from './Typography.js';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return merge(
    Card(theme),
    Lists(theme),
    Paper(theme),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    IconButton(theme),
    Autocomplete(theme),
  );
}
