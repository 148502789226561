import React, { useState, useContext } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, Button, styled, Modal, ModalProps, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import AuthContext from '../../../context/auth-context';
import { updateUser } from '../../../services/user.service';
import { IUser } from '../../../types/user';

const style = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  maxWidth: '100%',
  bgcolor: 'background.paper',
  p: 4,
  borderRadius: '16px',
});

const ModalStyle = styled(Modal)<ModalProps>(() => ({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export type UpdateUserFormType = {
  imageUrl: string;
};

type EditAvatarType = {
  user: IUser;
}

const EditAvatar: React.FC<EditAvatarType> = ({ user }) => {
  const { register, handleSubmit, reset } = useForm<UpdateUserFormType>();
  const { setUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data: UpdateUserFormType) => {
    setIsLoading(true);
    const updatedUser = await updateUser(user.id, data);
    if (updatedUser && updatedUser.id) {
      setUser(updatedUser);
      setTimeout(() => {
        setIsLoading(false);
        handleClose();
      }, 2000);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Button onClick={handleOpen} startIcon={<EditIcon />}>
        Edit
      </Button>
      <ModalStyle
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2">
            Change Profile Picture
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              required
              label="Picture URL"
              type="url"
              autoFocus
              defaultValue={user.imageUrl}
              helperText="Provide a link for your profile picture"
              {...register('imageUrl', { required: true })}
            />

            <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
              <Button onClick={handleClose} sx={{ mr: 2 }} color="inherit">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </ModalStyle>
    </Box>
  );
};

export default EditAvatar;
