import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import {
  Stack,
  Typography,
  Button,
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { invite } from '../../../../services/auth.service';
import { IUser } from '../../../../types/user';
import CustomModal, { CustomModalRef } from '../../../utils/modal/modal';

type CreateUserFormType = {
  firstname?: string;
  lastname?: string;
  email?: string;
  role?: 'admin' | 'standard';
  password: string;
};

type CreateUserProps = {
  addUser: (user: IUser) => void;
}

const CreateUser: React.FC<CreateUserProps> = ({ addUser }) => {
  const { register, handleSubmit, reset } = useForm<CreateUserFormType>();
  const [error, setError] = useState<undefined | string>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const modalRef = React.useRef<CustomModalRef>(null);

  const onClose = () => {
    reset();
  };

  const closeModal = () => {
    modalRef.current?.handleClose();
  };

  const onSubmit = async (data: CreateUserFormType) => {
    setIsLoading(true);
    try {
      const newData = { ...data };
      const createdUser = await invite(newData);
      if (createdUser && createdUser.id) {
        setError(undefined);
        setTimeout(() => {
          setIsLoading(false);
          closeModal();
          addUser(createdUser);
        }, 2000);
      } else {
        setIsLoading(false);
        setError('Something went wrong. Please try again later or contact the support.');
      }
    } catch (e: any) {
      if (e.response.status === 403) {
        setError('Please upgrade your licence to create more users. Change to the "Plan" tab to change your plan.');
      } else {
        setError(e.toString());
      }
      setIsLoading(false);
    }
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 4 }} spacing={2}>
      <Typography variant="h3" component="h3" gutterBottom>
        User
      </Typography>
      <CustomModal
        ref={modalRef}
        triggerNode={(
          <Button variant="contained" startIcon={<AddIcon />}>
            Invite User
          </Button>
                )}
        onClose={onClose}
      >
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Create new User
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="role">Role</InputLabel>
            <Select
              labelId="role"
              id="role"
              label="Role"
              defaultValue="standard"
              {...register('role', { required: true })}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="standard">Standard</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="normal"
            fullWidth
            required
            label="Email Address"
            type="email"
            autoFocus
            error={!!error}
            helperText={error && 'Please enter a valid email address'}
            {...register('email', {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Entered value does not match email format',
              },
            })}
          />
          {error
              && (
              <Box sx={{ my: 2, color: 'error.main' }}>
                { error }
              </Box>
              )}

          <Box sx={{ justifyContent: 'end', mt: 2, display: 'flex' }}>
            <Button onClick={closeModal} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={<PersonAddIcon />}
            >
              Invite User
            </LoadingButton>
          </Box>
        </Box>
      </CustomModal>
    </Stack>
  );
};

export default CreateUser;
