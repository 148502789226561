import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, Box, Typography, AccordionDetails } from '@mui/material';

const imgIcon = (url: string) => (
  <img
    src={url}
    alt="Confluence"
    loading="lazy"
    width="32"
    height="32"
  />
);

type IntegrationListItemProps = {
  title: string,
  iconUrl: string,
  disabled: boolean,
  open: boolean,
  handleToggle: () => void,
  children: React.ReactNode
}

const IntegrationListItem: React.FC<IntegrationListItemProps> = (
  { title, iconUrl, disabled, children, open, handleToggle },
) => (
  <Accordion disabled={disabled} expanded={open} onChange={handleToggle}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        { imgIcon(iconUrl) }
        <Typography component="h6" variant="h6" marginLeft={2}>{title}</Typography>
        { disabled && <Typography sx={{ color: 'text.secondary' }} marginLeft={2}>Coming soon</Typography> }
      </Box>
    </AccordionSummary>
    <AccordionDetails>
      { children }
    </AccordionDetails>
  </Accordion>
);

export default IntegrationListItem;
