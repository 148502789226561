import React, { useContext } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FemaleIcon from '@mui/icons-material/Female';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import MaleIcon from '@mui/icons-material/Male';
import TransgenderIcon from '@mui/icons-material/Transgender';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  TableBody,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
} from '@mui/material';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import NodeOptions from './node-options';
import AuthContext from '../../../context/auth-context';
import { IPerson } from '../../../types/person';
import PersonalFavoriteButton from '../../utils/personal-favorite-button/personal-favorite-button';

const EXCLUDE_LABELS = [
  'sex',
  'email',
  'phone',
  'name',
  'id',
  'label',
  'userId',
  'imageUrl',
  'verified',
];
const ALIAS_LABEL_NAMES = new Map<string, string>([
  ['firstname', 'Firstname'],
  ['lastname', 'Lastname'],
]);

type PersonNodeType = {
  person: IPerson;
}

const PersonNode: React.FC<PersonNodeType> = ({ person }) => {
  const { user } = useContext(AuthContext);
  const saveToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const genderIcon = () => {
    switch (person.sex) {
      case 'female':
        return <FemaleIcon fontSize="large" />;
        break;
      case 'male':
        return <MaleIcon fontSize="large" />;
        break;
      case 'diverse':
        return <TransgenderIcon fontSize="large" />;
        break;
      default:
        return <Box />;
        break;
    }
  };

  return (
    <Card sx={{ p: 2 }}>
      <Box sx={{ pb: 1, display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={person.imageUrl}
          sx={{ width: 56, height: 56, mr: 2 }}
        >
          {`${person.firstname[0]}${person.lastname[0]}`}
        </Avatar>
        <Typography
          id="modal-modal-title"
          variant="h3"
          component="h3"
          sx={{ mr: 1 }}
        >
          {person.name}
        </Typography>
        <Box>
          {genderIcon()}
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="Information about the person">
          <TableBody>
            {Object.keys(person)
              .filter((key) => (
                !EXCLUDE_LABELS.includes(key)
                && (typeof person[key] === 'string' || typeof person[key] === 'number')))
              .map((key) => (
                <TableRow
                  key={key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body1" component="div" fontWeight="bold">
                      {ALIAS_LABEL_NAMES.get(key) || key}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1" component="div">
                      {person[key]}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider />

      { person.email
        && (
        <Box sx={{ display: 'flex', pb: 1, pt: 2, alignItems: 'center' }}>
          <MailIcon sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ mr: 1 }}>
            <Link href={`mailto:${person.email}`}>{person.email}</Link>
          </Typography>
          <IconButton aria-label="copy" onClick={() => saveToClipboard(person.email || '')}>
            <ContentCopyIcon />
          </IconButton>
        </Box>
        )}

      { person.phone
        && (
        <Box sx={{ display: 'flex', py: 1, alignItems: 'center' }}>
          <LocalPhoneIcon sx={{ mr: 1 }} />
          <Typography variant="body1" sx={{ mr: 1 }}>
            <Link href={`tel:${person.phone}`}>{person.phone}</Link>
          </Typography>
          <IconButton aria-label="copy" onClick={() => saveToClipboard(person.phone || '')}>
            <ContentCopyIcon />
          </IconButton>
        </Box>
        )}

      {/* Action area in Node Card */}
      <Box sx={{ justifyContent: 'end', display: 'flex' }}>
        {user && <PersonalFavoriteButton user={user} nodeId={person.id} />}
        <NodeOptions
          node={person}
          isAdmin={user?.role === 'admin'}
          toggleEditMode={() => {
            throw new Error('Go to the settings page to edit the profile.');
          }}
        />
      </Box>
    </Card>
  );
};

export default PersonNode;
