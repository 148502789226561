import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

import { AccountMenuItem } from './types/account-menu-options';

export const MENU_OPTIONS: AccountMenuItem[] = [
  {
    label: 'Profile',
    icon: <PersonIcon />,
    linkTo: '/profile',
    guard: '',
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    linkTo: '/settings',
    guard: 'admin',
  },
];
