import axios from 'axios';

import { getConfig } from './utils';
import { IBox, IBoxDetails } from '../types/box';
import { INode, INodeDTO } from '../types/node';
import { IWebsiteDetails, IWebsite } from '../types/website';

const baseUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

export const createBox = async (
  details: IBoxDetails,
  nodes: INode[],
): Promise<IBox | undefined> => {
  try {
    const config = await getConfig();
    const boxDetails = { ...details, label: 'box' };
    const createdBox = (await axios.post(`${baseUrl}/create`, boxDetails, config)).data as IBox;
    const body = {
      fromId: createdBox.id,
      toIds: nodes.map((n) => n.id),
    };
    const connectedNodes = (await axios.post(`${baseUrl}/connect/oneToMultiple`, body, config))
      .data as INode[];
    return { ...createdBox, nodes: connectedNodes };
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const createNode = async (
  details: INodeDTO,
): Promise<INode | undefined> => {
  try {
    const config = await getConfig();

    const nodeDetails = { ...details };
    const createdNode = (await axios.post(`${baseUrl}/create`, nodeDetails, config)).data as INode;
    return createdNode;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};

export const createWebsite = async (
  details: IWebsiteDetails,
): Promise<IWebsite | undefined> => {
  const body = { ...details };
  try {
    const config = await getConfig();

    const createdWebsite = (await axios.post(
      `${baseUrl}/create`,
      { ...body, label: 'website' },
      config,
    )).data as IWebsite;
    return createdWebsite;
  } catch (error) {
    // No Cookie set
    return undefined;
  }
};
